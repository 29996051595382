<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  computed,
  onBeforeMount,
} from "vue";
import { Howl, Howler } from "howler";
import WebFont from "webfontloader";
import { triggerTTSMessage } from "../../../api/vtspog/index.js";
import { triggerTTSMessage as triggerTTSHelperMessage } from "../../../api/ttsHelper/index.js";

const emits = defineEmits(["ended", "error", "started"]);

const props = defineProps({
  currentAlert: {
    type: Object,
    required: true,
  },
  fontScale: {
    type: Number,
    default: 1,
  },
});

const DEFAULT_MEDIA_DURATION = 5000;

const mediaDuration = ref(DEFAULT_MEDIA_DURATION);
const videoPlayer = ref(null);

// loading states

const soundLoaded = ref(false);
const visualLoaded = ref(false);
const loaded = computed(() => soundLoaded.value && visualLoaded.value);
watch(
  () => loaded.value,
  (l) => {
    show.value = !!l;
    if (howlInstance.value && l) {
      howlInstance.value.play();
    }
  }
);

// show alert once visual and sound are both loaded
const show = ref(false);

const soundEnded = ref(false);
const visualEnded = ref(false);
const ended = computed(() => soundEnded.value && visualEnded.value);

watch(
  () => ended.value,
  (e) => {
    if (e) {
      endCurrentEvent();
    }
  }
);

// Sound management
const howlInstance = ref(null);

async function onMediaLoad() {
  if (videoPlayer.value) {
    mediaDuration.value = videoPlayer.value.duration * 1000;
  }

  // TODO check if font is loaded

  //let fontToCheck = `${props.currentAlert.settings.text.fontStyle.includes("bold") ? "bold " : ""}${props.currentAlert.settings.text.fontStyle.includes("italic") ? "italic " : ""}1rem '${props.currentAlert.settings.text.fontFamily}'`;

  // if (!document.fonts.check(fontToCheck)) {
  await new Promise((resolve) => {
    const families = [props.currentAlert.settings.text.fontFamily];
    if (props.currentAlert.settings.text.fontStyle.includes("bold")) {
      families.push(`${props.currentAlert.settings.text.fontFamily}:700`);
    }
    if (props.currentAlert.settings.text.fontStyle.includes("italic")) {
      families.push(`${props.currentAlert.settings.text.fontFamily}:italic`);
    }
    if (
      props.currentAlert.settings.text.fontStyle.includes("bold") &&
      props.currentAlert.settings.text.fontStyle.includes("italic")
    ) {
      families.push(`${props.currentAlert.settings.text.fontFamily}:700italic`);
    }
    try {
      WebFont.load({
        google: {
          families: families,
        },
        active: () => {
          resolve();
        },
        inactive: () => {
          resolve();
        },
        classes: false,
      });
    } catch (e) {
      console.error(e);
      resolve();
    }
  });
  //}

  visualLoaded.value = true;
}

function onMediaEnd() {
  visualEnded.value = true;
}

function onMediaMetaDataLoaded() {
  mediaDuration.value = videoPlayer.value.duration;
}

const start = ref(null);

function endCurrentEvent() {
  emits("ended");
}

//for visual only
watch(
  () => show.value,
  (showAlert) => {
    if (showAlert) {
      start.value = Date.now();
      if (
        props.currentAlert.media &&
        props.currentAlert.media.type === "video" &&
        videoPlayer.value
      ) {
        videoPlayer.value.play();
      }
      if (
        props.currentAlert.media &&
        props.currentAlert.media.type === "image"
      ) {
        if (props.currentAlert.sound) {
          visualEnded.value = true;
        }
      }
      // if we have a video or an image without a sound, hide the alert after the media duration minus the animation out duration
      if (props.currentAlert.media) {
        setTimeout(() => {
          show.value = false;
        }, mediaDuration.value - animationOutDuration.value);
        setTimeout(() => {
          onMediaEnd();
        }, mediaDuration.value);
      }
    }
  }
);

const ANIMATION_DURATION = 300;

const animationIn = computed(() => {
  return props.currentAlert?.settings?.animation.in || "";
});
const animationInDuration = computed(() => {
  return props.currentAlert?.settings?.animation.in ? ANIMATION_DURATION : 0;
});
const animationOut = computed(() => {
  return props.currentAlert?.settings?.animation.out || "";
});
const animationOutDuration = computed(() => {
  return props.currentAlert?.settings?.animation.out ? ANIMATION_DURATION : 0;
});
const direction = computed(() => {
  return props.currentAlert?.settings?.text.position || "bottom"; // "bottom" | "top" | "left" | "right" | "center"
});

const fontSize = computed(() => {
  let fontSize;
  switch (props.currentAlert?.settings?.text.fontSize) {
    case "sm":
      fontSize = 1;
      break;
    case "md":
      fontSize = 1.5;
      break;
    case "lg":
      fontSize = 2;
      break;
    default:
      fontSize = 1.5;
      break;
  }
  return props.fontScale * fontSize;
});

const textColor = computed(() => {
  return props.currentAlert?.settings?.text.color || "red";
});

const fontFamily = computed(() => {
  return props.currentAlert?.settings?.text.fontFamily || "Inter";
});

const fontStyle = computed(() => {
  return {
    fontWeight: props.currentAlert?.settings?.text.fontStyle?.includes("bold")
      ? "bold"
      : "normal",
    fontStyle: props.currentAlert?.settings?.text.fontStyle?.includes("italic")
      ? "italic"
      : "normal",
    textDecoration: props.currentAlert?.settings?.text.fontStyle?.includes(
      "underline"
    )
      ? "underline"
      : "none",
  };
});

const highlightColor = computed(() => {
  return props.currentAlert?.settings?.textHighlight?.color || "red";
});

const textStyle = computed(() => {
  return {
    color: textColor.value,
    fontFamily: fontFamily.value,
    fontSize: fontSize.value + "rem",
    lineHeight: "1rem",
    ...fontStyle.value,
  };
});
const viewerMessageTextStyle = computed(() => {
  return {
    ...textStyle.value,
    fontSize: fontSize.value * 0.6 + "rem",
  };
});

function convertTemplateToText(template, data) {
  let _template = template;
  const keys = Object.keys(data).map((key) => "#" + key);
  if (keys.length === 0) {
    return template;
  }

  // use regex to replace all instances of the key with the value and split into an array while keeping the delimiters

  // generate regex from data keys

  const regex = new RegExp(`(${keys.join("|")})`, "g");
  const splitTemplate = _template
    .split(regex)
    .filter(Boolean)
    .map((part) => {
      if (keys.includes(part)) {
        return {
          type: "highlight",
          value: data[part.replace("#", "")],
        };
      }
      return {
        type: "text",
        value: part,
      };
    });
  return splitTemplate;
}

const streamerMessage = computed(() => {
  return convertTemplateToText(
    props.currentAlert?.settings?.textTemplate || "",
    props.currentAlert?.data || {}
  );
});

const viewerMessage = computed(() => {
  if (!props.currentAlert?.settings?.showViewerMessage) {
    return "";
  }
  return props.currentAlert?.userMessage || "";
});

function calculateStrokeTextCSS(strokeWidth, strokeColor) {
  const shadows = [];
  for (let j = 0; j < strokeWidth; j++) {
    const steps = Math.pow(j + 2, strokeWidth > 10 ? 1 : 2);
    for (let i = 0; i < steps; i++) {
      const angle = (i * 2 * Math.PI) / steps;
      const cos = Math.round(10000 * Math.cos(angle)) / 10000;
      const sin = Math.round(10000 * Math.sin(angle)) / 10000;
      shadows.push(
        `calc(${j}px * ` + cos + `) calc(${j}px * ` + sin + `) 0 ${strokeColor}`
      );
    }
  }

  const res = shadows.join(", ");
  return res;
}

const textBorderStyle = computed(() => {
  if (props.currentAlert?.settings?.textOutline) {
    let textBorderSize;
    switch (props.currentAlert?.settings?.textOutline.width) {
      case "sm":
        textBorderSize = 2 * props.fontScale;
        break;
      case "md":
        textBorderSize = 4 * props.fontScale;
        break;
      case "lg":
        textBorderSize = 8 * props.fontScale;
        break;
      case "none":
      default:
        textBorderSize = 0;
        break;
    }

    return {
      color: textColor.value,
      fontFamily: fontFamily.value,
      fontSize: fontSize.value + "rem",

      lineHeight: "1rem",
      ...fontStyle.value,
      textShadow: calculateStrokeTextCSS(
        textBorderSize,
        props.currentAlert?.settings?.textOutline.color || "black"
      ),
    };
  }
  return {
    display: "none",
  };
});

const viewerMessageTextBorderStyle = computed(() => {
  return {
    ...textBorderStyle.value,
    fontSize: fontSize.value * 0.6 + "rem",
  };
});

onBeforeMount(() => {
  if (props.currentAlert.sound) {
    howlInstance.value = new Howl({
      src: [
        `https://firebasestorage.googleapis.com/v0/b/bakuma-vstream-cdn/o/sounds%2Fglobal%2F${props.currentAlert.sound.id}.ogg?alt=media`,
      ],
      preload: true,
      autoplay: false,
      volume: 1,
      html5: true, //for cors
      onend: () => {
        soundEnded.value = true;
      },
      onload: () => {
        soundLoaded.value = true;
        if (
          !props.currentAlert.media ||
          props.currentAlert.media.type === "image"
        ) {
          mediaDuration.value = howlInstance.value.duration() * 1000;
        }
      },
      onloaderror: (e) => {
        console.error(e);
        soundEnded.value = true;
      },
      onplayerror: (e) => {
        console.error(e);

        soundEnded.value = true;
      },
      onunlock: () => {
        // don't trigger before it's loaded
        if (howlInstance.value.state() === "loaded") {
          soundLoaded.value = true;
        }
      },
    });
    howlInstance.value.load();
  } else {
    soundLoaded.value = true;
    soundEnded.value = true;
  }

  if (props.currentAlert.media) {
    //videoPlayer.value.load();
  } else {
    // if we have no media, mark it as loaded
    visualLoaded.value = true;
    visualEnded.value = true;
  }

  // if we have a user message and TTS enabled, trigger the TTS
  // we currently only use 3rd party tools for it
  console.log(props.currentAlert.settings.tts, props.currentAlert.userMessage);
  if (props.currentAlert.settings.tts && props.currentAlert.userMessage) {
    console.log("1");
    console.log(props.currentAlert.settings.tts);
    if (props.currentAlert.settings.tts.origin === "vts-pog") {
      console.log("2");

      triggerTTSMessage(
        props.currentAlert.userMessage,
        props.currentAlert.data.user
      );
    }
    if (props.currentAlert.settings.tts.origin === "tts-helper") {
      triggerTTSHelperMessage(
        props.currentAlert.userMessage,
        props.currentAlert.data.user
      );
    }
  }
});
</script>

<template>
  <transition
    :duration="{ enter: animationInDuration, leave: animationOutDuration }"
    :enter-active-class="
      animationIn ? `animate__animated animate__${animationIn}` : ''
    "
    :leave-active-class="
      animationOut ? `animate__animated animate__${animationOut}` : ''
    "
    appear
  >
    <div
      class="alert-box"
      v-show="show"
      :key="'alert'"
      :class="{
        'direction-bottom': direction === 'bottom',
        'direction-top': direction === 'top',
        'direction-left': direction === 'left',
        'direction-right': direction === 'right',
        'direction-center': direction === 'center',
      }"
      :style="{
        '--animate-duration': `${animationInDuration}ms`,
      }"
    >
      <div class="alert-box-media" v-if="currentAlert.media">
        <img
          class="alert-box-image"
          @load="onMediaLoad"
          @error="onMediaEnd"
          v-if="currentAlert.media.type === 'image'"
          :src="
            currentAlert.media.src ||
            `https://firebasestorage.googleapis.com/v0/b/bakuma-vstream-cdn/o/user%2F${currentAlert.media.channelId}%2Fmedia%2F${currentAlert.media.id}.webp?alt=media`
          "
        />
        <video
          class="alert-box-video"
          v-else-if="currentAlert.media.type === 'video'"
          ref="videoPlayer"
          @canplay="onMediaLoad"
          @error="onMediaEnd"
          @ended="onMediaEnd"
          @loadedmetadata="onMediaMetaDataLoaded"
          muted
          :src="
            currentAlert.media.src ||
            `https://firebasestorage.googleapis.com/v0/b/bakuma-vstream-cdn/o/user%2F${currentAlert.media.channelId}%2Fmedia%2F${currentAlert.media.id}.webm?alt=media`
          "
        />
      </div>

      <div style="display: flex; align-items: center; flex-direction: column">
        <div class="alert-box-text-container">
          <div
            :style="textBorderStyle"
            v-if="streamerMessage && streamerMessage.length"
            class="alert-box-text alert-box-text-border"
          >
            <template v-for="(part, i) in streamerMessage">
              <span :key="'text' + i" v-if="part.type === 'text'">{{
                part.value
              }}</span>
              <span
                :key="'highlight' + i"
                v-else-if="part.type === 'highlight'"
                :style="{
                  ...textBorderStyle,
                  color: highlightColor,
                }"
                class="alert-box-text-highlight animate__animated animate__slower animate__infinite"
                :class="[
                  `animate__${currentAlert.settings.textHighlight.animation}`,
                ]"
              >
                {{ part.value }}
              </span>
            </template>
          </div>
          <div
            :style="textStyle"
            v-if="streamerMessage && streamerMessage.length"
            class="alert-box-text"
          >
            <template v-for="(part, i) in streamerMessage">
              <span :key="'text' + i" v-if="part.type === 'text'">{{
                part.value
              }}</span>
              <span
                :key="'highlight' + i"
                v-else-if="part.type === 'highlight'"
                :style="{
                  ...fontStyle,
                  color: highlightColor,
                }"
                class="alert-box-text-highlight animate__animated animate__slower animate__infinite"
                :class="[
                  `animate__${currentAlert.settings.textHighlight.animation}`,
                ]"
              >
                {{ part.value }}
              </span>
            </template>
          </div>
        </div>
        <div
          class="alert-box-text-container alert-box-text-user-message"
          style="width: 100%"
          v-if="viewerMessage"
        >
          <div
            :style="viewerMessageTextBorderStyle"
            class="alert-box-text alert-box-text-border"
          >
            {{ viewerMessage }}
          </div>
          <div :style="viewerMessageTextStyle" class="alert-box-text">
            {{ viewerMessage }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.alert-box {
  height: 100%;
  width: 100%;
  max-height: 100vh;
  max-width: 100vw;
  display: flex;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .alert-box-media {
    flex: 1;
    max-height: 100%;
    max-width: 100%;
    min-width: 50%;
    min-height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .alert-box-video,
    .alert-box-image {
      display: block;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center bottom;
    }
  }

  .alert-box-text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 75%;
    &.alert-box-text-user-message {
      margin-top: 0.25rem;
    }

    position: relative;

    .alert-box-text {
      position: relative;
      line-height: 1.5 !important;
      width: 100%;

      .alert-box-text-highlight {
        display: inline-block;
        position: relative;
      }

      &.alert-box-text-border {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  &.direction-bottom {
    flex-direction: column;

    .alert-box-text-container {
      text-align: center;
    }
  }

  &.direction-top {
    flex-direction: column-reverse;

    .alert-box-text-container {
      text-align: center;
    }
  }

  &.direction-left {
    flex-direction: row-reverse;

    .alert-box-text-container {
      text-align: right;
      margin-right: 0.5rem;
    }
  }

  &.direction-right {
    flex-direction: row;

    .alert-box-text-container {
      text-align: left;
      margin-left: 0.5rem;
    }
  }

  &.direction-center {
    justify-content: center;
    align-items: center;

    .alert-box-media {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 95%;
      height: 95%;
    }

    .alert-box-text-container {
      width: max-content;
      text-align: center;
    }
  }
}
</style>
