import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";
import "animate.css";

import "./assets/main.css";

const app = createApp(App);

app.use(router);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://04cd0509ba694e9b92bf7693ded9a104@o4505583323643904.ingest.sentry.io/4505583325806592",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https://bakuma.tv"],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [
          window.location.origin,
          "securetoken.googleapis.com/v1/token",
        ],
        networkRequestHeaders: ["X-Custom-Header"],
        networkResponseHeaders: ["X-Custom-Header"],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  app.config.errorHandler = (err, vm, info) => {
    Sentry.captureException(err);
  };
}

app.mount("#app");
