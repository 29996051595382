<template>
  <div
    class="follower-goal"
    :class="{
      'follower-goal-left-align': align === 'left',
      'follower-goal-right-align': align === 'right',
      'follower-goal-center-align': align === 'center',
    }"
    :style="containerStyle"
  >
    <div class="bar-title">
      {{ titleText }}
    </div>
    <div class="progress-bar" v-if="showBar">
      <div class="progress-bar-background" :style="backgroundStyle"></div>
      <div class="progress-bar-fill" :style="fillBarStyle"></div>
      <div class="progress-bar-content">
        <div class="progress-bar-label-start">
          {{ followerCount }}
        </div>
        <div class="progress-bar-label-template">
          {{ barText }}
        </div>
        <div class="progress-bar-label-end">
          {{ settings.goal }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref, watch, onBeforeUnmount } from "vue";
import WebFont from "webfontloader";
import { CUSTOM_EVENTS } from "../../../api/events.js";

export default {
  name: "FollowerGoalWidget",
  props: {
    settings: {
      required: true,
    },
    user: {
      required: true,
    },
    cache: {
      type: Object,
      required: false,
    },
    fetchTwitchFollowerCount: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    let fetchInterval;
    const followerCount = ref(props.cache?.followers || 0);

    function onChatMessage(event) {
      if (event.detail.type !== "new_follower") {
        return;
      }
      followerCount.value += 1;
    }

    onBeforeMount(() => {
      window.addEventListener(
        CUSTOM_EVENTS.CHANNEL_FOLLOWED_EVENT,
        onChatMessage
      );
      WebFont.load({
        google: {
          families: [props.settings.fontFamily || "Inter"],
        },
      });
      // fetch follower count every 5 minutes to keep it up to date if we miss an event
      fetchInterval = setInterval(() => {
        props.fetchTwitchFollowerCount().then((res) => {
          if (res >= 0) {
            followerCount.value = res;
          }
        });
      }, 5 * 60 * 1000);
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        CUSTOM_EVENTS.CHANNEL_FOLLOWED_EVENT,
        onChatMessage
      );
      clearInterval(fetchInterval);
    });

    watch(
      () => props.settings.fontFamily,
      () => {
        let fontName = props.settings.fontFamily || "Inter";

        WebFont.load({
          google: {
            families: [fontName + ":400,700"],
          },
        });
      }
    );

    const progress = computed(() => {
      return followerCount.value / props.settings.goal;
    });

    const showBar = computed(() => {
      return props.settings.showBar;
    });
    const titleTemplate = computed(() => {
      return props.settings.titleTemplate || "%follows%";
    });
    const barTemplate = computed(() => {
      return props.settings.barTemplate || "%follows%";
    });

    const titleText = computed(() => {
      return titleTemplate.value
        .replaceAll("%follows%", followerCount.value)
        .replaceAll(
          "%progress%",
          Math.min(100, Math.round(progress.value * 100)).toString()
        )
        .replaceAll("%goal%", props?.settings?.goal || "0");
    });
    const barText = computed(() => {
      return barTemplate.value
        .replaceAll("%follows%", followerCount.value)
        .replaceAll(
          "%progress%",
          Math.min(100, Math.round(progress.value * 100)).toString()
        )
        .replaceAll("%goal%", props?.settings?.goal || "0");
    });

    const backgroundColor = computed(() => {
      return props.settings.backgroundColor || "#ffffff";
    });
    const barColor = computed(() => {
      return props.settings.barColor || "#ff0000";
    });

    const align = computed(() => {
      return props.settings.align || "left";
    });

    const goal = computed(() => {
      return props.settings.goal || 0;
    });

    const backgroundStyle = computed(() => {
      return {
        backgroundColor: backgroundColor.value,
      };
    });

    const fillBarStyle = computed(() => {
      return {
        width: `${Math.min(100, progress.value * 100)}%`,
        backgroundColor: barColor.value,
      };
    });

    const containerStyle = computed(() => {
      return {
        fontFamily: props.settings.fontFamily || "Inter" + ", sans-serif",
        fontSize: props?.settings?.fontSize
          ? props.settings.fontSize + "px"
          : "1rem",
        color: props.settings.fontColor || "#000000",
      };
    });
    return {
      align,
      backgroundStyle,
      fillBarStyle,
      followerCount,
      showBar,
      titleText,
      barText,
      progress,
      goal,
      containerStyle,
    };
  },
};
</script>

<style scoped lang="scss">
.follower-goal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .bar-title {
    font-weight: bold;
    font-size: 1.2rem;
    width: 100%;
  }
  .progress-bar {
    margin-top: 0.5rem;

    position: relative;
    flex: 1;
    border-radius: 1rem;
    overflow: hidden;
    .progress-bar-background {
      z-index: 1;
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 1rem;
    }
    .progress-bar-fill {
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      transition: width 0.3s ease-in-out;
      z-index: 2;
      width: 100%;
    }
    .progress-bar-content {
      font-weight: bold;
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      align-items: center;
      z-index: 10;
      padding-left: 1rem;
      padding-right: 1rem;
      .progress-bar-label-start {
      }
      .progress-bar-label-template {
        flex: 1;
        text-align: center;
      }
      .progress-bar-label-end {
      }
    }
  }

  &.follower-goal-left-align {
    .progress-bar-fill {
      left: 0;
    }
    .bar-title {
      text-align: left;
    }
  }
  &.follower-goal-right-align {
    .progress-bar-fill {
      right: 0;
    }
    .progress-bar-content {
      flex-direction: row-reverse;
    }
    .bar-title {
      text-align: right;
    }
  }
  &.follower-goal-center-align {
    .progress-bar-fill {
      left: 50%;
      transform: translateX(-50%);
    }
    .bar-title {
      text-align: center;
    }
  }
}
</style>
