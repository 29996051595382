import axios from "axios";

export async function checkVTSPOGHealth() {
  return axios.get("http://localhost:3800/status").then((response) => {
    return response.data === "1";
  });
}
export async function triggerTTSMessage(message, username) {
  return axios
    .get("http://localhost:3800/pog", {
      params: {
        text: message,
        username: username,
      },
    })
    .then((response) => {
      return "ok";
    });
}
