<script setup>
import {
  onBeforeMount,
  onBeforeUnmount,
  ref,
  computed,
  watch,
  onMounted,
  nextTick,
} from "vue";
import { CUSTOM_EVENTS } from "../../../api/events.js";
import AlertItem from "./AlertItem.vue";

// TODO handle transition in and out
// TODO improve error handling to avoid any stuck state
// handle custom text and replace values in the template

const props = defineProps({
  settings: {
    required: true,
  },
  user: {
    required: true,
  },
  muted: {
    default: false,
  },
  cache: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["alert-ended"]);

const alertQueue = ref([]);
const events = ref([]); // events describing the sound and media linked to a specific event type

const currentAlert = computed(() => {
  return alertQueue.value[0] || null;
});

const ALLOWED_EVENTS = [
  "channel.follow",
  "channel.raid",
  "channel.cheer",
  "channel.subscribe",
  "channel.subscription.gift",
];

function convertTemplateToText(template, data) {
  let _template = template;
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return template;
  }

  keys.forEach((key) => {
    _template = _template.replaceAll(
      "#" + key,
      `<span class="alert-box-highlight">${data[key]}</span>`,
    );
  });

  return _template;
}

function convertTemplateToRawText(template, data) {
  return template.replace(/\${(.*?)}/g, (match, token) => {
    let value = data[token];
    if (value === undefined) {
      value = "";
    }
    return value;
  });
}

function mapEventToAlert(alert, event) {
  let data = {
    user: "User",
    value: "0",
    message: "Hello World",
  };
  let userMessage = "";

  // update with Twitch values now
  switch (alert.type) {
    case "channel.follow":
      data = {
        user: alert.data.user_name || alert.data.user_login, // who followed
      };
      break;
    case "channel.cheer":
      data = {
        user: alert.data.user_name || alert.data.user_login, // who subbed
        message: "", // TODO support user message
        value: alert.data.bits, // how much was sent
      };
      userMessage = alert.data.message;

      break;
    case "channel.subscribe":
      let tier = "Tier 1";
      if (alert.data.tier === "2000") {
        tier = "Tier 2";
      } else if (alert.data.tier === "3000") {
        tier = "Tier 3";
      }
      data = {
        user: alert.data.user_name || alert.data.user_login, // who subbed
        value: alert.data.cumulative_months, // nb of months
        tier: tier, // sub tier
      };
      userMessage = alert.data.message;

      break;
    case "channel.subscription.gift":
      data = {
        user: alert.data.user_name || alert.data.user_login, // who subbed
        value: alert.data.total, // nb gifts
        tier: alert.data.tier, // sub tier
      };
      break;
    case "channel.raid":
      data = {
        user:
          alert.data.from_broadcaster_user_name ||
          alert.data.from_broadcaster_user_login, // channel who raids
        value: alert.data.viewers, // raiders amount
      };
      break;
  }
  return {
    data,
    type: alert.type,
    text: convertTemplateToText(event.settings.textTemplate, data),
    originalText: convertTemplateToRawText(event.settings.textTemplate, data),
    sound: props.muted ? undefined : alert.sound, // id
    media: alert.media, // id and type
    userMessage: userMessage,
  };
}

const ALERT_TYPE_TO_EVENT_TYPE = {
  "channel.follow": "new_follower",
  "channel.raid": "raid",
  "channel.cheer": "cheer",
  "channel.subscription.gift": "subgift",
  "channel.subscribe": "subscription",
};

const SUB_TIER_VALUE = {
  1000: 1,
  2000: 2,
  3000: 3,
};

function onNewEvent(e) {
  const alert = e.detail;
  if (ALLOWED_EVENTS.includes(alert.type)) {
    const alertType = ALERT_TYPE_TO_EVENT_TYPE[alert.type];

    if (!ALERT_TYPE_TO_EVENT_TYPE[alert.type]) {
      console.error("NO EVENT TYPE FOUND FOR ALERT", alert.type);
      return;
    }
    // TODO check conditions
    let foundEvent;
    if (alert.isTest && alert.mockEvent) {
      foundEvent = alert.mockEvent;
    } else {
      events.value.forEach((event) => {
        if (event.type === alertType) {
          if (event.type === "new_follower") {
            foundEvent = event;
          } else if (event.type === "raid") {
            if (alert.data.viewers >= event.conditions[0]) {
              if (
                foundEvent &&
                foundEvent.conditions[0] < event.conditions[0]
              ) {
                foundEvent = event;
              } else if (!foundEvent) {
                foundEvent = event;
              }
            }
          } else if (event.type === "cheer") {
            if (alert.data.bits >= event.conditions[0]) {
              if (
                foundEvent &&
                foundEvent.conditions[0] < event.conditions[0]
              ) {
                foundEvent = event;
              } else if (!foundEvent) {
                foundEvent = event;
              }
            }
          } else if (event.type === "subscription") {
            if (
              SUB_TIER_VALUE[alert.data.tier] &&
              SUB_TIER_VALUE[alert.data.tier] >= event.conditions[0] &&
              alert.data.cumulative_months >= event.conditions[1]
            ) {
              if (
                foundEvent &&
                foundEvent.conditions[0] <= event.conditions[0] &&
                foundEvent.conditions[1] <= event.conditions[1]
              ) {
                foundEvent = event;
              } else if (!foundEvent) {
                foundEvent = event;
              }
            }
          } else if (event.type === "subgift") {
            if (
              alert.data.total >= event.conditions[0] &&
              SUB_TIER_VALUE[alert.data.tier] &&
              SUB_TIER_VALUE[alert.data.tier] >= event.conditions[1]
            ) {
              if (
                foundEvent &&
                foundEvent.conditions[0] <= event.conditions[0] &&
                foundEvent.conditions[1] <= event.conditions[1]
              ) {
                foundEvent = event;
              } else if (!foundEvent) {
                foundEvent = event;
              }
            }
          }
        }
      });
    }

    //const foundEvent = events.value[ALERT_TYPE_TO_EVENT_TYPE[alert.type]];
    if (!foundEvent) {
      console.warn("NO EVENT FOUND FOR ALERT", alert.type);
      // there is no event linked to the alert
      return;
    }

    if (!foundEvent.media && !foundEvent.sound) {
      // there is no media or sound linked to the event
      return;
    }
    const fixedAlert = mapEventToAlert(alert, foundEvent); // TODO move that to the AlertItem directly
    alertQueue.value.push({
      id: alert.type + "-" + alert.id,
      ...fixedAlert,
      sound: props.muted ? undefined : foundEvent.sound, // id
      media: foundEvent.media, // id and type
      settings: foundEvent.settings,
      tts: foundEvent.tts || undefined,
    });
  }
}

function onAlertEnded() {
  if (!currentAlert.value) {
    console.warn("NO CURRENT ALERT");
    return;
  }
  emit("alert-ended", currentAlert.value);
  alertQueue.value.shift();
}

watch(
  () => props.cache.events,
  (newEvents) => {
    events.value = newEvents || [];
  },
);

const alertContainer = ref(null);
const containerWidth = ref(0);
const containerHeight = ref(0);
const containerResizeObserver = new ResizeObserver((entries) => {
  const entry = entries[0];
  const { width, height } = entry.contentRect;
  containerWidth.value = width;
  containerHeight.value = height;
  // TODO handle resize
});

onMounted(() => {
  nextTick(() => {
    containerResizeObserver.observe(alertContainer.value);
    containerWidth.value = alertContainer.value.clientWidth;
    containerHeight.value = alertContainer.value.clientHeight;
  });
});

const fontScale = computed(() => {
  const size = Math.min(containerWidth.value, containerHeight.value);
  return size / 400;
});

onBeforeMount(() => {
  events.value = props.cache.events || [];
  //events.value = props.settings?.events || []; // TODO check if it's worth keeping them here, if the events are too big it might be a problem
  window.addEventListener(CUSTOM_EVENTS.CHANNEL_FOLLOWED_EVENT, onNewEvent);
  window.addEventListener(CUSTOM_EVENTS.CHEER_EVENT, onNewEvent);
  window.addEventListener(
    CUSTOM_EVENTS.SUBSCRIPTION_ACTIVATED_EVENT,
    onNewEvent,
  );
  window.addEventListener(CUSTOM_EVENTS.SUBSCRIPTION_GIFTED_EVENT, onNewEvent);
  window.addEventListener(CUSTOM_EVENTS.RAID_EVENT, onNewEvent);
});

onBeforeUnmount(() => {
  window.removeEventListener(
    CUSTOM_EVENTS.SUBSCRIPTION_GIFTED_EVENT,
    onNewEvent,
  );
  window.removeEventListener(CUSTOM_EVENTS.CHANNEL_FOLLOWED_EVENT, onNewEvent);
  window.removeEventListener(CUSTOM_EVENTS.CHEER_EVENT, onNewEvent);
  window.removeEventListener(
    CUSTOM_EVENTS.SUBSCRIPTION_ACTIVATED_EVENT,
    onNewEvent,
  );
  window.removeEventListener(CUSTOM_EVENTS.RAID_EVENT, onNewEvent);

  containerResizeObserver.disconnect();
});
</script>

<template>
  <div
    ref="alertContainer"
    style="
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: relative;
    "
  >
    <AlertItem
      v-if="currentAlert"
      :current-alert="currentAlert"
      :key="currentAlert.id"
      @ended="onAlertEnded"
      @error="onAlertEnded"
      style="position: relative; height: 100%; width: 100%"
      :font-scale="fontScale"
    />
  </div>
</template>

<style scoped></style>
