<template>
  <div class="scene" v-if="!error">
    <Widget
      v-if="initialized && scene"
      :scene="scene"
      :user="channel"
      :cache="cache"
      :fetchVStreamFollowerCount="fetchVStreamFollowerCount"
      :fetch-twitch-follower-count="fetchTwitchFollowerCount"
      :origin="origin"
    />
  </div>
  <div v-else class="error">{{ error }}</div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed, ref, onMounted, onBeforeUnmount, watch } from "vue";
import Widget from "../components/Widgets/Widget";
import { CUSTOM_EVENTS } from "../api/events";
import {
  fetchCustomToken,
  fetchTwitchFollowerCount as fetchTwitchFollowerCountAPI,
  listenToAlertBoxEvents,
  listenToChannelEvents,
  logIn,
  onUserStateChanged,
  watchScene,
} from "../firebase";
import * as Sentry from "@sentry/vue";
import {
  fetchChannelBadges,
  fetchChannelCheerMotes,
  TwitchChatClient,
} from "../api/twitch/index.js";
import { GLOBAL_BADGES } from "../api/twitch/globalBadges.js";
import cheerEmotes from "../api/twitch/cheers.js";

const WIDGET_TYPES = [
  "chat",
  "viewer-count",
  "emote-wall",
  "follower-count",
  "follower-goal",
];

const WIDGETS_WITH_METRICS = ["follower-count", "follower-goal"];

export default {
  name: "SceneView",
  components: { Widget },
  setup() {
    const route = useRoute();
    const sceneId = computed(() => route.params.sceneId);
    const channel = ref(null); // from auth
    const scene = ref(null); // from auth
    const identities = ref({}); // from API call
    const vStreamRealTime = ref(null);

    const eventsListener = ref(null);
    const sceneEventsListener = ref(null);
    const chatClient = ref(null);

    const origin = computed(() => {
      // if we have query param origin
      /*if (route.query.origin && route.query.origin === "twitch") {
        return "twitch";
      }*/
      return "twitch";
    });

    const error = ref("");

    const cache = ref({
      chat: {
        messages: [],
        connectedAt: 0,
      },
      viewerCount: 0,
      identities: {}, // social networks of the streamer, cache them to avoid multiple api calls
      socialNetworks: {},
      followers: 0,
      badges: GLOBAL_BADGES,
      cheermotes: cheerEmotes,
      events: [],
    });

    const initialized = ref(false);

    function handleEvent(event) {
      cache.value.viewerCount = parseInt(event.detail.viewerCount);
    }

    function handleChatMessages(event) {
      if (!event) {
        return;
      }

      const type = event.type;
      let eventTypeToPush;
      switch (type) {
        case "channel.follow": {
          eventTypeToPush = CUSTOM_EVENTS.CHANNEL_FOLLOWED_EVENT;
          break;
        }
        case "channel.chat.message":
          // not supported yet
          break;
        case "channel.chat.message_delete":
          // not supported yet
          break;
        case "channel.subscribe":
          // use chat for this
          break;
        case "channel.subscription.gift":
          // use chat for this
          break;
        case "channel.cheer": {
          //TODO
          eventTypeToPush = CUSTOM_EVENTS.CHEER_EVENT;

          break;
        }
        case "channel.raid": {
          //TODO
          eventTypeToPush = CUSTOM_EVENTS.RAID_EVENT;

          break;
        }
        case "channel.poll.begin":
        case "channel.poll.progress":
        case "channel.poll.end": {
          eventTypeToPush = CUSTOM_EVENTS.POLL_EVENT;
          break;
        }
        case "channel.prediction.begin":
        case "channel.prediction.progress":
        case "channel.prediction.lock":
        case "channel.prediction.end": {
          eventTypeToPush = CUSTOM_EVENTS.PREDICTION_EVENT;
          break;
        }
        case "channel.goal.begin":
        case "channel.goal.progress":
        case "channel.goal.end": {
          eventTypeToPush = CUSTOM_EVENTS.GOAL_EVENT;
          break;
        }
        case "channel.hype_train.begin":
        case "channel.hype_train.progress":
        case "channel.hype_train.end":
          eventTypeToPush = CUSTOM_EVENTS.HYPE_TRAIN_EVENT;
          break;

        case "channel.shoutout.create":
          eventTypeToPush = CUSTOM_EVENTS.SHOUT_OUT_EVENT;
          break;
      }

      if (eventTypeToPush) {
        const customEvent = new CustomEvent(eventTypeToPush, {
          detail: event,
        });
        window.dispatchEvent(customEvent);
      }
    }

    function onNewEvent(event) {
      if (event.subtype === "cheer") {
        const customEvent = new CustomEvent(CUSTOM_EVENTS.CHEER_EVENT, {
          detail: event,
        });
        window.dispatchEvent(customEvent);
      }
      // hype train
      if (event.subtype === "hypeTrain") {
        const customEvent = new CustomEvent(CUSTOM_EVENTS.HYPE_TRAIN_EVENT, {
          detail: event,
        });
        window.dispatchEvent(customEvent);
      }
      if (event.subtype === "raid") {
        const customEvent = new CustomEvent(CUSTOM_EVENTS.RAID_EVENT, {
          detail: event,
        });
        window.dispatchEvent(customEvent);
      }
      if (event.subtype === "follow") {
        const customEvent = new CustomEvent(
          CUSTOM_EVENTS.CHANNEL_FOLLOWED_EVENT,
          {
            detail: event,
          },
        );
        window.dispatchEvent(customEvent);
      }
      // SUB EVENTS ARE FROM TWITCH CHAT
      // we only allow test events
      if (event.subtype === "subscription" && event.isTest && event.mockEvent) {
        const customEvent = new CustomEvent(
          CUSTOM_EVENTS.SUBSCRIPTION_ACTIVATED_EVENT,
          {
            detail: event,
          },
        );
        window.dispatchEvent(customEvent);
      }
      if (event.subtype === "subgift" && event.isTest && event.mockEvent) {
        const customEvent = new CustomEvent(
          CUSTOM_EVENTS.SUBSCRIPTION_GIFTED_EVENT,
          {
            detail: event,
          },
        );
        window.dispatchEvent(customEvent);
      }
    }

    let userInfosRefreshInterval;
    onMounted(() => {
      if (!sceneId.value) {
        return;
      }

      // WAIT FOR USER TO INIT SCENE DATA
      onUserStateChanged((user) => {
        if (user) {
          // when user is initialized init realtime watcher
          watchScene(sceneId.value, (s) => {
            if (s) {
              scene.value = s;
            }
          });
          // TODO move this when the scene is loaded
        }
      });

      // LOG IN PART
      fetchCustomToken(sceneId.value)
        .then(async (res) => {
          console.log("=== GOT CUSTOM TOKEN ===");

          const {
            token,
            user,
            identities: _identities,
            scene,
            vstreamPubSubToken = "",
            meta,
          } = res;

          if (scene.type === "viewer-count") {
            error.value = "This widget is not supported anymore";
            return;
          }
          identities.value = _identities;
          if (origin.value === "vstream") {
            error.value =
              "VSTREAM closed down and is not supported anymore. if you haven't migrated your account yet, please contact us on Discord";
            return;
          }
          if (origin.value === "twitch" && !identities.value.TWITCH) {
            error.value =
              "TWITCH identity not found, please link it to the dashboard";
            return;
          }

          channel.value = user;
          //sceneInitialized.value = true;
          Sentry.setUser({
            id: identities.value.TWITCH.accountId,
          });

          if (!scene) {
            error.value = "Scene not found";
            return;
          }

          scene.value = scene; // faster first render

          // not all widgets need the metrics
          /*if (WIDGETS_WITH_METRICS.includes(scene.value.type)) {
            const metrics = meta.metrics;

            cache.value.followers =
              metrics?.followersCount || metrics?.followers || 0;
            userInfosRefreshInterval = setInterval(
              async () => {
                try {
                  const metrics = await fetchChannelMetrics();
                  if (metrics) {
                    cache.value.followers = metrics?.followersCount || 0;
                  }
                } catch (err) {
                  console.error(err);
                  // do nothing
                }
              },
              1000 * 60 * 10,
            ); // every 10 minutes we refresh the metrics to stay up to date
          }*/

          /*if (origin.value === "vstream" && vstreamPubSubToken) {
            vStreamRealTime.value = new VStreamRealTime(
              _identities.VSTREAM.accountId,
              vstreamPubSubToken,
              {
                onOpen: () => {
                  console.log("=== VSTREAM PUBSUB CONNECTED ===");
                  initialized.value = true;
                },
                onClose: () => {
                  console.error("=== VSTREAM PUBSUB DISCONNECTED ===");
                },
              },
            );
            vStreamRealTime.value.start();
          } else*/
          if (origin.value === "twitch") {
            cache.value.followers = meta?.metrics?.followers || 0;

            // TODO connect to firebase to get events
            // TODO connect to twitch chat
            chatClient.value = new TwitchChatClient(
              identities.value.TWITCH.identity.login,
            );
            fetchChannelCheerMotes(
              user.id,
              //100901794,
              identities.value.TWITCH.secret.accessToken,
            ).then((cheerMotes) => {
              cache.value.cheermotes = cheerMotes;
              chatClient.value.setCheermotes(cheerMotes);
            });

            fetchChannelBadges(
              identities.value.TWITCH.identity.id,
              //100901794,
              identities.value.TWITCH.secret.accessToken,
            ).then((badges) => {
              cache.value.badges = [...GLOBAL_BADGES, ...badges];
            });

            // TODO add event handler for subscriptions and sub gift
            //new TwitchChatClient(res.identities.TWITCH.identity.login);
            //error.value = "Twitch support is not implemented yet";
          }
          console.log("=== LOGGING IN ===");
          logIn(token)
            .then(async () => {
              // do nothing, wait for the user auth callback
            })
            .catch((err) => {
              console.error(err);
              Sentry.addBreadcrumb({
                category: "scene",
                message: JSON.stringify(err),
                level: "error",
              });
              Sentry.captureException(err);

              //Sentry.captureException(err);
              //  refresh page

              error.value =
                "Error while connecting, please refresh the page or contact us on Discord";

              //throw err;
            });
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 400) {
              switch (e.response.data.error) {
                case "INVALID_REFRESH_TOKEN":
                  error.value =
                    "Error with your VStream account, please log out and log back in on the dashboard";
              }
            }
            //console.log(error.response.headers);
          }
        });

      window.addEventListener(
        CUSTOM_EVENTS.VIEWER_COUNT_UPDATED_EVENT,
        handleEvent,
      );
      window.addEventListener(
        CUSTOM_EVENTS.CHAT_CREATED_EVENT,
        handleChatMessages,
      );
    });

    onBeforeUnmount(() => {
      window.removeEventListener(
        CUSTOM_EVENTS.VIEWER_COUNT_UPDATED_EVENT,
        handleEvent,
      );
      window.removeEventListener(
        CUSTOM_EVENTS.CHAT_CREATED_EVENT,
        handleChatMessages,
      );

      if (eventsListener.value) {
        eventsListener.value();
      }
      /*
      if (vStreamRealTime.value) {
        vStreamRealTime.value.stop();
      }*/
      clearInterval(userInfosRefreshInterval);
    });

    watch(scene, (newScene, oldScene) => {
      if (newScene && !oldScene) {
        if (origin.value === "twitch") {
          console.log("=== INIT TWITCH ===");
          eventsListener.value = listenToChannelEvents(
            channel.value.id,
            (event) => {
              onNewEvent(event);
            },
          );
          eventsListener.value = listenToAlertBoxEvents(
            scene.value.id,
            (events) => {
              // TODO
              cache.value.events = events;
            },
          );
          initialized.value = true;
        }
      }
    });
    async function fetchVStreamFollowerCount() {
      return {
        followers:
          cache?.value?.user?.channelProfile?.channelFollowing?.followerCount ||
          0,
      };
    }
    async function fetchTwitchFollowerCount() {
      return fetchTwitchFollowerCountAPI(channel.value.id);
    }
    return {
      fetchVStreamFollowerCount,
      initialized,
      channel,
      cache,
      scene,
      error,
      origin,
      fetchTwitchFollowerCount,
    };
  },
};
</script>

<style scoped>
.scene {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  position: relative;
}

.error {
  color: red;
  font-size: 2rem;
}
</style>
