export const CUSTOM_EVENTS = {
  //VIEWER_JOINED_EVENT: "Bakuma-ViewerJoinedEvent",
  //VIEWER_LEFT_EVENT: "Bakuma-ViewerLeftEvent",
  CHAT_CREATED_EVENT: "Bakuma-ChatCreatedEvent",
  VIEWER_COUNT_UPDATED_EVENT: "Bakuma-ViewerCountUpdatedEvent",
  CHAT_DELETED_EVENT: "Bakuma-ChatDeletedEvent",
  USER_CHAT_DELETED_EVENT: "Bakuma-UserChatsDeletedEvent",
  LIKE_COUNT_UPDATED_EVENT: "Bakuma-LikeCountUpdatedEvent",
  LIVE_STREAM_ENDED_EVENT: "Bakuma-LiveStreamEndedEvent",
  LIVE_STREAM_WENT_LIVE_EVENT: "Bakuma-LiveStreamWentLiveEvent",
  CHANNEL_FOLLOWED_EVENT: "Bakuma-ChannelFollowedEvent",
  UPLIFTING_CHAT_CREATED_EVENT: "Bakuma-UpliftingChatCreatedEvent",
  SUBSCRIPTION_ACTIVATED_EVENT: "Bakuma-SubscriptionActivatedEvent",
  SUBSCRIPTION_GIFTED_EVENT: "Bakuma-SubscriptionGiftedEvent",
  METEOR_SHOWER_RECEIVED_EVENT: "Bakuma-MeteorShowerReceivedEvent",
  CHEER_EVENT: "Bakuma-CheerEvent",
  RAID_EVENT: "Bakuma-RaidEvent",
  POLL_EVENT: "Bakuma-PollEvent",
  GOAL_EVENT: "Bakuma-GoalEvent",
  PREDICTION_EVENT: "Bakuma-PredictionEvent",
  HYPE_TRAIN_EVENT: "Bakuma-HypeTrainEvent",
  SHOUT_OUT_EVENT: "Bakuma-ShoutOutEvent",
};

export const VSTREAM_EVENTS_TYPES = {
  KEEP_ALIVE: "keep_alive",
  CHAT_CREATED: "chat_created",
  CHAT_DELETED: "chat_deleted",
  CHATTER_CHATS_DELETED: "chatter_chats_deleted",
  NEW_FOLLOWER: "new_follower",
  LIVESTREAM_STARTED: "livestream_started",
  LIVE_STREAM_ENDED: "livestream_ended",
  METEOR_SHOWER_RECEIVED: "meteor_shower_received",
  UPLIFTING_CHAT_SENT: "uplifting_chat_sent",
  SUBSCRIPTION_RENEWED: "subscription_renewed",
  SUBSCRIPTIONS_GIFTED: "subscriptions_gifted",
};

export const VSTREAM_EVENT_TO_CUSTOM_EVENT = {
  [VSTREAM_EVENTS_TYPES.CHAT_CREATED]: CUSTOM_EVENTS.CHAT_CREATED_EVENT,
  [VSTREAM_EVENTS_TYPES.CHAT_DELETED]: CUSTOM_EVENTS.CHAT_DELETED_EVENT,
  [VSTREAM_EVENTS_TYPES.CHATTER_CHATS_DELETED]:
    CUSTOM_EVENTS.USER_CHAT_DELETED_EVENT,
  [VSTREAM_EVENTS_TYPES.NEW_FOLLOWER]: CUSTOM_EVENTS.CHANNEL_FOLLOWED_EVENT,
  [VSTREAM_EVENTS_TYPES.LIVESTREAM_STARTED]:
    CUSTOM_EVENTS.LIVE_STREAM_WENT_LIVE_EVENT,
  [VSTREAM_EVENTS_TYPES.LIVE_STREAM_ENDED]:
    CUSTOM_EVENTS.LIVE_STREAM_ENDED_EVENT,
  [VSTREAM_EVENTS_TYPES.METEOR_SHOWER_RECEIVED]:
    CUSTOM_EVENTS.METEOR_SHOWER_RECEIVED_EVENT,
  [VSTREAM_EVENTS_TYPES.UPLIFTING_CHAT_SENT]:
    CUSTOM_EVENTS.UPLIFTING_CHAT_CREATED_EVENT,
  [VSTREAM_EVENTS_TYPES.SUBSCRIPTION_RENEWED]:
    CUSTOM_EVENTS.SUBSCRIPTION_ACTIVATED_EVENT,
  [VSTREAM_EVENTS_TYPES.SUBSCRIPTIONS_GIFTED]:
    CUSTOM_EVENTS.SUBSCRIPTION_GIFTED_EVENT,
};

export const TWITCH_EVENT_TO_CUSTOM_EVENT = {
  chat: CUSTOM_EVENTS.CHAT_CREATED_EVENT,
  connected: CUSTOM_EVENTS.LIVE_STREAM_WENT_LIVE_EVENT,
  disconnected: CUSTOM_EVENTS.LIVE_STREAM_ENDED_EVENT,
  reconnect: CUSTOM_EVENTS.LIVE_STREAM_WENT_LIVE_EVENT,
  subscription: CUSTOM_EVENTS.SUBSCRIPTION_ACTIVATED_EVENT,
  resub: CUSTOM_EVENTS.SUBSCRIPTION_ACTIVATED_EVENT,
  subgift: CUSTOM_EVENTS.SUBSCRIPTION_GIFTED_EVENT,
  messagedeleted: CUSTOM_EVENTS.CHAT_DELETED_EVENT,
  cheer: CUSTOM_EVENTS.CHEER_EVENT,
};
