import axios from "axios";

export async function triggerTTSMessage(message, username) {
  return axios
    .get("http://localhost:3800/12589", {
      params: {
        text: message,
        username: username,
        platform: "twitch",
        charlimit: 600,
      },
    })
    .then((response) => {
      return "ok";
    });
}
