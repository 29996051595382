// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  signInWithCustomToken,
  getAuth,
  inMemoryPersistence,
  setPersistence,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  orderBy,
  limit,
  query,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";
import * as Sentry from "@sentry/vue";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBO5lL0QjTvPi0e8fkx_m1Z4Iu6h947tAQ",
  authDomain: "bakuma-vstream-98ce3.firebaseapp.com",
  databaseURL:
    "https://bakuma-vstream-98ce3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bakuma-vstream-98ce3",
  storageBucket: "bakuma-vstream-98ce3.appspot.com",
  messagingSenderId: "513634410636",
  appId: "1:513634410636:web:9e8080b87921e722971ba9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();

// prevent user auth persistence so that we can use custom tokens from our endpoint
setPersistence(auth, inMemoryPersistence);

export async function logIn(token) {
  return await signInWithCustomToken(auth, token).catch((error) => {
    console.error(error);
    throw error;
  });
}

export async function onUserStateChanged(cb) {
  onAuthStateChanged(auth, (user) => {
    if (cb) {
      cb(user);
    }
  });
}

export function fetchCustomToken(code) {
  return axios
    .post(
      "https://europe-west1-bakuma-vstream-98ce3.cloudfunctions.net/auth/browser-source",
      {
        code: code,
      },
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
      //location.reload(true);
      throw error;
    });
}

const sceneCache = {};
let reconnectInterval;

export function watchScene(sceneId, onSceneChanged) {
  // watch using firestore

  if (sceneCache[sceneId]) {
    sceneCache[sceneId].cb.push(onSceneChanged);
  } else {
    const docRef = doc(db, "scenes", sceneId);
    const unsub = onSnapshot(
      docRef,
      (doc) => {
        if (reconnectInterval) {
          clearInterval(reconnectInterval);
          reconnectInterval = undefined;
        }
        if (doc.exists()) {
          sceneCache[sceneId].cb.forEach((cb) => {
            cb({
              ...doc.data(),
              id: doc.id,
            });
          });
        }
      },
      (error) => {
        delete sceneCache[sceneId];
        console.error(error);
        Sentry.captureException(error);

        // wait a few seconds and reconnect
        if (!reconnectInterval) {
          reconnectInterval = setInterval(() => {
            watchScene(sceneId, onSceneChanged);
          }, 5000);
        }
      },
    );
    sceneCache[sceneId] = { cb: [onSceneChanged], unsub };
  }
}

export function fetchChannelMetrics() {
  const functions = getFunctions(app, "europe-west1");
  const getVstreamChannelFollowersFunc = httpsCallable(
    functions,
    "getVStreamChannelMetrics",
  );
  return getVstreamChannelFollowersFunc().then(async (result) => {
    // Read result of the Cloud Function.
    /** @type {any} */

    return result.data;
  });
}
export function fetchTwitchFollowerCount() {
  const functions = getFunctions(app, "europe-west1");
  const getVstreamChannelFollowersFunc = httpsCallable(
    functions,
    "getTwitchFollowerCount",
  );
  return getVstreamChannelFollowersFunc().then(async (result) => {
    // Read result of the Cloud Function.
    /** @type {any} */

    return result.data;
  });
}

export async function refreshVStreamToken() {
  const functions = getFunctions(app, "europe-west1");
  const refreshVStreamTokenFunc = httpsCallable(
    functions,
    "refreshVStreamToken",
  );
  return refreshVStreamTokenFunc().then(async (result) => {
    // Read result of the Cloud Function.
    /** @type {any} */

    return result.data;
  });
}
export async function refreshVStreamPubSubToken() {
  console.log("refreshing pubsub token");
  const functions = getFunctions(app, "europe-west1");
  const refreshVStreamPubSubTokenFunc = httpsCallable(
    functions,
    "refreshVStreamPubSubToken",
  );
  return refreshVStreamPubSubTokenFunc().then(async (result) => {
    // Read result of the Cloud Function.
    /** @type {any} */
    return result.data;
  });
}

export async function listenToChannelEvents(channelId, cb) {
  // listen to the channel events using firebase listener
  // order by created_at date
  const q = query(
    collection(db, "users", channelId, "events"),
    orderBy("createdAt", "desc"),
    limit(10),
  );
  let isFirst = true;
  return onSnapshot(q, (snapshot) => {
    if (isFirst) {
      console.log("CONNECTED TO EVENT LISTENER");
    }
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        if (isFirst) {
          return;
        }

        cb({
          ...change.doc.data(),
          id: change.doc.id,
        });
      }
    });
    isFirst = false;
  });
}

export async function listenToAlertBoxEvents(sceneId, cb) {
  // listen to the channel events using firebase listener
  // order by created_at date
  const q = query(collection(db, "scenes", sceneId, "eventVariations"));
  let isFirst = true;
  return onSnapshot(q, (snapshot) => {
    if (isFirst) {
      console.log("CONNECTED TO ALERT BOX EVENTS LISTENER");
    }
    const events = [];
    snapshot.docChanges().forEach((change) => {
      events.push(change.doc.data());
    });
    cb(events);
  });
}
