<template>
  <TwitchChatWidget
    v-if="scene.type === 'chat' && origin === 'twitch'"
    :settings="scene.settings"
    :user="user"
    :cache="cache"
    style="width: 100%"
  />

  <TwitchEmoteWallWidget
    v-if="scene.type === 'emote-wall' && origin === 'twitch'"
    :settings="scene.settings"
    :user="user"
    :cache="cache"
    style="width: 100%; height: 100%"
  />

  <TwitchFollowerGoalWidget
    v-if="scene.type === 'follower-goal' && origin === 'twitch'"
    :settings="scene.settings"
    :user="user"
    :cache="cache"
    :fetchTwitchFollowerCount="fetchTwitchFollowerCount"
    style="width: 100%"
  />

  <TwitchFollowerCountWidget
    v-if="scene.type === 'follower-count' && origin === 'twitch'"
    :settings="scene.settings"
    :user="user"
    :cache="cache"
    :fetchTwitchFollowerCount="fetchTwitchFollowerCount"
    style="width: 100%"
  />

  <HypeTrainWidget
    v-if="scene.type === 'hype-train' && origin === 'twitch'"
    :settings="scene.settings"
    :user="user"
    :cache="cache"
    style="width: 100%"
  />
  <AlertBoxWidget
    v-if="scene.type === 'alert-box' && origin === 'twitch'"
    :cache="cache"
    :user="user"
    :settings="scene.settings"
  />
</template>

<script>
import TwitchChatWidget from "./Twitch/ChatWidget";
import TwitchEmoteWallWidget from "./Twitch/EmoteWallWidget";
import TwitchFollowerGoalWidget from "./Twitch/FollowerGoalWidget";
import TwitchFollowerCountWidget from "./Twitch/FollowerCountWidget";
import HypeTrainWidget from "./Twitch/HypeTrainWidget.vue";
import AlertBoxWidget from "./AlertBoxWidget/AlertBoxWidget.vue";
export default {
  name: "Widget",
  components: {
    AlertBoxWidget,
    HypeTrainWidget,

    TwitchChatWidget,
    TwitchEmoteWallWidget,
    TwitchFollowerGoalWidget,
    TwitchFollowerCountWidget,
  },
  props: {
    scene: {},
    user: {},
    cache: {},
    origin: {
      default: "twitch",
    },
    fetchVStreamFollowerCount: {
      default: () => async () => {
        return {
          followers: 0,
        };
      },
    },
    fetchTwitchFollowerCount: {
      default: () => async () => {
        return {
          followers: 0,
        };
      },
    },
  },
};
</script>

<style scoped></style>
