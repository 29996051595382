import tmi from "tmi.js";
import {
  CUSTOM_EVENTS,
  TWITCH_EVENT_TO_CUSTOM_EVENT,
  VSTREAM_EVENT_TO_CUSTOM_EVENT,
  VSTREAM_EVENTS_TYPES,
} from "../events.js";
import cheermotes, { getEmoteObject } from "./cheers.js";
import axios from "axios";

const CLIENT_ID = "dq3yq15thqsi0ka6m50h3vc6zcwm9n";

const TIERS_TO_VALUES = {
  1000: "1000",
  2000: "2000",
  3000: "3000",
  Prime: "1000",
};

export class TwitchChatClient {
  subgiftcache = {
    // [giftEventId]:{
    //   total: 0, // total gift
    //   giftEvent
    // }
  };
  cheermotes = cheermotes;
  badges = [];

  user = null;
  channel;

  constructor(channel) {
    this.client = new tmi.Client({
      connection: {
        secure: true,
        reconnect: true,
      },
      identity: {
        username: "justinfan115610",
        password: "",
      },
      channels: ["#" + channel],
      //channels: ["#mistermv"],
    });
    this.channel = channel;

    this.client.on("connected", this.onConnected.bind(this));
    //chat & cheer events
    this.client.on("cheer", this.onCheer.bind(this));
    this.client.on("chat", this.onChat.bind(this));
    this.client.on("subscription", this.onSubscription.bind(this));
    this.client.on("resub", this.onSubscription.bind(this));
    this.client.on("subgift", this.onSubGift.bind(this));
    this.client.on("submysterygift", this.onSubMysteryGift.bind(this));
    // TODO add support for deleted messages
    // sub and resub

    // sub gift
    this.client.connect();
  }

  TMIToEventSubChatMessage(channel, userstate, message, self) {
    //split message into fragments
    // possible fragments are text, mention, cheermote, emote
    const emotes = [];
    Object.entries(userstate.emotes || {}).forEach(([id, positions]) => {
      for (let i = 0; i < positions.length; i++) {
        const [start, end] = positions[i].split("-");
        try {
          emotes.push({
            type: "emote",
            text: message.slice(parseInt(start), parseInt(end) + 1),
            emote: {
              id,

              emote_set_id: "",
              owner_id: "",
              formats: "",
            },

            start: parseInt(start),
            end: parseInt(end),
          });
        } catch (e) {
          console.error(e);
        }
      }
    });

    const prefixes = this.cheermotes.map((cheermote) => cheermote.prefix);
    // create a regex to match cheermotes prefixes + numbers after
    const cheermoteRegex = new RegExp(`(${prefixes.join("|")})([0-9]+)`, "g");

    // use regex to get cheermotes and their positions
    const _cheermotes = [];
    let match;
    while ((match = cheermoteRegex.exec(message))) {
      const [full, prefix, amount] = match;
      _cheermotes.push({
        type: "cheer",
        start: match.index,
        end: match.index + full.length,
        text: full,
        cheermote: {
          prefix,
          bits: parseInt(amount),
          tier: getEmoteObject(prefix, parseInt(amount)).min_bits,
        },
      });
    }

    const mentions = [];
    const mentionRegex = /@([a-zA-Z0-9_]+)/g;
    while ((match = mentionRegex.exec(message))) {
      const [full, username] = match;
      mentions.push({
        type: "mention",
        start: match.index,
        end: match.index + full.length,
        text: full,
        mention: {
          user_id: "",
          user_name: username,
          user_login: username,
        },
      });
    }

    // get text fragments (rest of the message)

    let baseFragments = [emotes, _cheermotes, mentions]
      .flat()
      .sort((a, b) => a.start - b.start);

    let fragments = [];
    let lastEnd = 0;
    baseFragments.forEach((fragment) => {
      if (fragment.start > lastEnd) {
        fragments.push({
          type: "text",
          start: lastEnd,
          end: fragment.start - 1,
          text: message.slice(lastEnd, fragment.start),
        });
      }
      fragments.push(fragment);
      lastEnd = fragment.end + 1;
    });
    if (lastEnd < message.length) {
      fragments.push({
        type: "text",
        start: lastEnd,
        end: message.length - 1,
        text: message.slice(lastEnd),
      });
    }

    const data = {
      broadcaster_user_id: "", // TODO get the proper id
      broadcaster_user_name: "", // TODO get channel user name
      broadcaster_user_login: "", // TODO get channel user login
      chatter_user_id: userstate["user-id"],
      chatter_user_name: userstate["display-name"] || userstate.username,
      chatter_user_login: userstate.username,
      message_id: userstate.id,
      message: {
        text: message,
        fragments: fragments,
        message_type: "text",
        badges: Object.entries(userstate.badges || {}).map(
          ([set, version]) => ({
            set_id: set,
            id: version,
            info: "",
          }),
        ),
        cheer: fragments.find((f) => f.type === "cheer")
          ? { bits: parseInt(userstate.bits) }
          : undefined,
        color: userstate.color,
        reply: undefined, //see later for the reply stuff
        channel_points_custom_reward_id: "", // see later for the channel points stuff
      },
    };
    return data;
  }
  onConnected(address, port) {
    console.log("CONNECTED TO TWITCH CHAT");
  }
  onChat(channel, userstate, message, self) {
    const data = this.TMIToEventSubChatMessage(
      channel,
      userstate,
      message,
      self,
    );
    // TODO send event
    const event = new CustomEvent(CUSTOM_EVENTS.CHAT_CREATED_EVENT, {
      detail: {
        type: "channel.chat.message",
        subtype: "chat",
        channelId: "",
        createdAt: new Date().toISOString(),
        origin: "TWITCH",
        data,
      },
    });
    window.dispatchEvent(event);
  }

  onCheer(channel, userstate, message) {
    const data = this.TMIToEventSubChatMessage(
      channel,
      userstate,
      message,
      false,
    );

    const cheerData = {
      is_anonymous: userstate["display-name"] === "AnAnonymousCheerer",
      user_id: userstate["user-id"],
      user_name: userstate["display-name"] || userstate.username,
      user_login: userstate.username,
      broadcaster_user_id: "", // TODO get the proper id
      broadcaster_user_name: "", // TODO get channel user name
      broadcaster_user_login: "", // TODO get channel user login
      message: message,
      bits: parseInt(userstate.bits),
    };
    const event = new CustomEvent(CUSTOM_EVENTS.CHEER_EVENT, {
      detail: { ...cheerData },
    });

    const chatEvent = new CustomEvent(CUSTOM_EVENTS.CHAT_CREATED_EVENT, {
      detail: {
        type: "channel.chat.message",
        subtype: "chat",
        channelId: "",
        createdAt: new Date().toISOString(),
        origin: "TWITCH",
        data,
      },
    });
    window.dispatchEvent(event);
    window.dispatchEvent(chatEvent);
  }

  onSubscription(channel, username, method, message, userstate) {
    let cumulative_months = 1;
    try {
      cumulative_months =
        parseInt(userstate["msg-param-cumulative-months"]) || 1;
    } catch {
      cumulative_months = 1;
    }

    const data = {
      user_id: userstate["user-id"],
      user_login: userstate.username,
      user_name: userstate["display-name"] || userstate.username,
      broadcaster_user_id: "", // TODO get the proper id
      broadcaster_user_name: "", // TODO get channel user name
      broadcaster_user_login: "", // TODO get channel user login
      tier: TIERS_TO_VALUES[userstate["msg-param-sub-plan"]] || "1000",
      // TODO convert message to message object with fragments
      message: message,
      cumulative_months: cumulative_months, //that's what we want to use
      streak_months: cumulative_months,
      duration_months: cumulative_months,
      gifted: userstate["msg-param-was-gifted"] === "true",
    };
    const event = new CustomEvent(TWITCH_EVENT_TO_CUSTOM_EVENT.subscription, {
      detail: {
        type: "channel.subscribe",
        subtype: "subscription",
        data: data,
      },
    });
    window.dispatchEvent(event);
  }

  onSubGift(channel, username, streakMonths, recipient, methods, userstate) {
    const data = {
      user_id: userstate["user-id"],
      user_login: userstate.username,
      user_name: userstate["display-name"] || userstate.username,
      broadcaster_user_id: "", // TODO get the proper id
      broadcaster_user_name: "", // TODO get channel user name
      broadcaster_user_login: "", // TODO get channel user login,
      recipient_user_id: userstate["msg-param-recipient-id"],
      recipient_user_login: userstate["msg-param-recipient-user-name"],
      recipient_user_name: userstate["msg-param-recipient-display-name"],
      total: parseInt(userstate["msg-param-sender-count"] || "1"),
      tier: userstate["msg-param-sub-plan"],
      cumulative_total: null,
      is_anonymous: userstate["display-name"] === "AnAnonymousGifter",
    };
    if (!userstate["msg-param-community-gift-id"]) {
      const event = new CustomEvent(TWITCH_EVENT_TO_CUSTOM_EVENT.subgift, {
        detail: {
          type: "channel.subscription.gift",
          subtype: "subgift",
          data: data,
        },
      });
      window.dispatchEvent(event);
    }
  }

  onSubMysteryGift(channel, username, numbOfSubs, methods, userstate) {
    const data = {
      user_id: userstate["user-id"],
      user_login: userstate.username,
      user_name: userstate["display-name"] || userstate.username,
      broadcaster_user_id: "", // TODO get the proper id
      broadcaster_user_name: "", // TODO get channel user name
      broadcaster_user_login: "", // TODO get channel user login
      total: parseInt(userstate["msg-param-sender-count"] || "1"),
      tier: userstate["msg-param-sub-plan"],
      cumulative_total: null,
      is_anonymous: userstate["display-name"] === "AnAnonymousGifter",
    };

    const event = new CustomEvent(TWITCH_EVENT_TO_CUSTOM_EVENT.subgift, data);

    window.dispatchEvent(event);
  }

  setCheermotes(cheermotes) {
    this.cheermotes = cheermotes;
  }
}

export function fetchChannelCheerMotes(channelId, token) {
  return axios
    .get(
      `https://api.twitch.tv/helix/bits/cheermotes?broadcaster_id=${channelId}`,
      {
        headers: {
          "Client-ID": CLIENT_ID,
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => response.data.data);
}

export function fetchChannelEmotes(channelId, token) {
  return axios.get(
    `https://api.twitch.tv/helix/chat/emotes?broadcaster_id=${channelId}`,
    {
      headers: {
        "Client-ID": CLIENT_ID,
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export function fetchUserInfoFromLogin(login, token) {
  return axios
    .get(`https://api.twitch.tv/helix/users?login=${login}`, {
      headers: {
        "Client-ID": CLIENT_ID,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.data[0]);
}

export function fetchChannelBadges(channelId, token) {
  return axios
    .get(
      `https://api.twitch.tv/helix/chat/badges?broadcaster_id=${channelId}`,
      {
        headers: {
          "Client-ID": CLIENT_ID,
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => response.data.data)
    .catch((e) => {
      if (e.response) {
        console.error(e.response.data);
      }
      return [];
    });
}
