<template>
  <div
    v-if="state === 'STARTED'"
    class="hype-train-container"
    :key="lastEvent.data.id"
    :style="containerStyle"
  >
    <div class="hype-train-background" :style="backgroundStyle" />
    <div class="hype-train-content" :style="contentStyle">
      <div class="hype-train-content-body">
        <div class="hype-train-content-header">
          <div class="hype-train-content-level" :style="pillStyle">
            Lvl {{ level }}
          </div>
          <div><strong>Hype Train</strong></div>
        </div>
        <div class="hype-train-content-description">
          {{ settings.engagementText }}
        </div>
      </div>
      <div class="hype-train-content-side">
        <div class="hype-train-content-time">{{ remainingTime }}</div>
        <div class="hype-train-content-progress">
          <AnimatedNumber :value="progress" />%
        </div>
      </div>
    </div>
    <Transition name="hype-train-fade" mode="out-in">
      <div
        class="hype-train-level-up"
        :key="showLevelUpResetKey"
        v-if="showLevelUp"
        style="height: 100%; width: 100%; position: absolute; top: 0; left: 0"
        :style="levelUpStyle"
      >
        <template v-if="levelUpStep === 'PERCENT'">
          <div class="hype-train-level-up-text">100%</div>
        </template>
        <template v-else-if="levelUpStep === 'HYPE'">
          <div class="hype-train-level-up-hype-text">
            <span>Hyyyyypeeee</span>
            <img
              src="https://static-cdn.jtvnw.net/emoticons/v2/305954156/default/light/1.0"
            />
            <img
              src="https://static-cdn.jtvnw.net/emoticons/v2/305954156/default/light/1.0"
            />
            <img
              src="https://static-cdn.jtvnw.net/emoticons/v2/305954156/default/light/1.0"
            />
            <img
              src="https://static-cdn.jtvnw.net/emoticons/v2/305954156/default/light/1.0"
            />
          </div>
        </template>
        <template v-else-if="levelUpStep === 'COMPLETED'">
          <div class="hype-train-level-up-level-text">
            Level {{ levelProgression }} completed
          </div>
        </template>
        <Confetti
          style="height: 100%; width: 100%; position: absolute; top: 0; left: 0"
        />
      </div>
    </Transition>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import Confetti from "../../ui/Confetti.vue";
import AnimatedNumber from "../../ui/AnimatedNumber.vue";
import WebFont from "webfontloader";
import { CUSTOM_EVENTS } from "../../../api/events.js";

function dateDiffToString(a, b) {
  let diff = Math.abs(a - b);

  let ms = diff % 1000;
  diff = (diff - ms) / 1000;
  let s = diff % 60;
  diff = (diff - s) / 60;
  let m = diff % 60;

  let ss = s <= 9 && s >= 0 ? `0${s}` : s;
  let mm = m <= 9 && m >= 0 ? `0${m}` : m;

  return mm + ":" + ss;
}

function getColorByBgColor(hexColor, reverse = false, threshold = 50) {
  if (!hexColor) {
    return "";
  }
  // If a leading # is provided, remove it
  if (hexColor.slice(0, 1) === "#") {
    hexColor = hexColor.slice(1);
  }

  // Convert to RGB value
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  if (reverse) {
    return yiq >= threshold ? "white" : "black";
  }
  return yiq >= threshold ? "black" : "white";
}

export default {
  name: "HypeTrainWidget",
  components: { AnimatedNumber, Confetti },
  props: {
    settings: {
      required: true,
    },
    user: {
      required: true,
    },
  },
  setup(props) {
    // TODO use props to catch user settings

    const lastEvent = ref(null);
    const progress = computed(() => {
      if (!(lastEvent && lastEvent.value)) {
        return 0;
      }
      return Math.round(
        ((lastEvent.value.data.progress || 0) /
          (lastEvent.value.data.goal || 0)) *
          100,
      );
    });
    const currentTime = ref(Date.now());
    const level = computed(() => {
      return (lastEvent && lastEvent.value && lastEvent.value.data.level) || 1;
    });
    let showLevelUp = ref(false);
    let showLevelUpResetKey = ref(Date.now());
    let levelProgression = ref(1);
    let showLevelUpAnimation = ref(["PERCENT", "HYPE", "COMPLETED"]);
    let showEndAnimation = ref(false);
    let showEnd = ref(false);
    let levelUpStep = ref("PERCENT");

    async function playPercentAnimation() {
      return new Promise((resolve) => {
        if (showLevelUpAnimation.value.includes("PERCENT")) {
          levelUpStep.value = "PERCENT";
          showLevelUp.value = true;
          setTimeout(() => {
            resolve();
          }, 3000);
        } else {
          resolve();
        }
      });
    }
    async function playHypeAnimation() {
      return new Promise((resolve) => {
        if (showLevelUpAnimation.value.includes("HYPE")) {
          levelUpStep.value = "HYPE";
          showLevelUp.value = true;
          setTimeout(() => {
            resolve();
          }, 4500);
        } else {
          resolve();
        }
      });
    }
    async function playCompletedAnimation() {
      return new Promise((resolve) => {
        if (showLevelUpAnimation.value.includes("COMPLETED")) {
          levelUpStep.value = "COMPLETED";
          showLevelUp.value = true;
          setTimeout(() => {
            resolve();
          }, 3000);
        } else {
          resolve();
        }
      });
    }
    async function playEndAnimation() {
      return new Promise((resolve) => {
        if (showEndAnimation.value) {
          showEnd.value = true;
          setTimeout(() => {
            showEnd.value = false;
            resolve();
          }, 3000);
        } else {
          resolve();
        }
      });
    }

    function startShowLevelAnimation() {
      const id = lastEvent.value.data.id;

      playPercentAnimation()
        .then(() => playHypeAnimation())
        .then(() => playCompletedAnimation())
        .then(() => {
          // if we haven't started a new hype train, shouldn't happen live, but that's a good safety
          if (id === lastEvent.value.data.id) {
            levelProgression.value = levelProgression.value + 1;
            if (level.value > levelProgression.value) {
              showLevelUpResetKey.value = Date.now();
              startShowLevelAnimation();
            } else {
              showLevelUp.value = false;
            }
          }
        });
    }

    watch(level, function (newLevel, oldLevel) {
      if (
        !showLevelUp.value &&
        newLevel > oldLevel &&
        newLevel > levelProgression.value
      ) {
        startShowLevelAnimation();
      }
    });

    const backgroundColor = computed(
      () => (props.settings && props.settings.backgroundColor) || "#9146ff",
    );
    const textColor = computed(
      () =>
        (props.settings && getColorByBgColor(props.settings.backgroundColor)) ||
        "#000000",
    );
    const levelUpColor = computed(
      () =>
        (props.settings &&
          getColorByBgColor(props.settings.backgroundColor, false, 200)) ||
        "#000000",
    );
    const pillBgColor = computed(
      () =>
        (props.settings && getColorByBgColor(props.settings.backgroundColor)) ||
        "#000000",
    );
    const pillTextColor = computed(
      () =>
        (props.settings &&
          getColorByBgColor(props.settings.backgroundColor, true)) ||
        "#000000",
    );

    const state = computed(() => {
      if (lastEvent && lastEvent.value) {
        switch (lastEvent.value.type) {
          case "channel.hype_train.begin":
          case "channel.hype_train.progress":
            return "STARTED";

          case "channel.hype_train.end":
            return "ENDED";
        }
      }
      return "ENDED";
    });

    watch(state, (newState) => {
      if (newState === "ENDED") {
        showLevelUp.value = false;
        levelProgression.value = 1;
      }
      if (newState === "STARTED") {
        showLevelUp.value = false;
        levelProgression.value = 1;
      }
    });

    return {
      backgroundColor,
      startShowLevelAnimation,
      showLevelUpResetKey,
      levelProgression: levelProgression,
      showLevelUp: showLevelUp,
      showLevelUpAnimation,
      currentTime: currentTime,
      currentTimeUpdateInterval: ref(0),
      lastEvent,
      level: level,
      state,
      levelUpStep, // PERCENT / HYPE / COMPLETED
      progress: progress,
      backgroundStyle: computed(() => ({
        width: `${progress.value}%`,
        background: backgroundColor.value,
        overflow: "hidden",
      })),
      contentStyle: computed(() => ({
        color: textColor.value,
      })),
      levelUpStyle: computed(() => ({
        background: backgroundColor.value,
        color: levelUpColor.value,
      })),
      pillStyle: computed(() => ({
        background: pillBgColor.value,
        color: pillTextColor.value,
      })),
      containerStyle: computed(() => ({
        background: pillTextColor.value,
      })),
      remainingTime: computed(() => {
        if (!lastEvent) return "";
        if (!currentTime) return "";
        // avoid going backward
        if (
          new Date(
            lastEvent.value.data.expires_at || lastEvent.value.data.ended_at,
          ) < new Date(currentTime.value)
        ) {
          return "00:00";
        }
        return dateDiffToString(
          new Date(
            lastEvent.value.data.expires_at || lastEvent.value.data.ended_at,
          ),
          new Date(currentTime.value),
        );
      }),
    };
  },
  methods: {
    onNewEvent(e) {
      const event = e.detail;
      if (event.subtype !== "hypeTrain") {
        return;
      }
      if (!this.lastEvent) {
        this.levelProgression = event.data.data.level;
      }

      this.lastEvent = event.data;
    },
    updateCurrentTime() {
      this.currentTime = Date.now();
    },
  },
  beforeMount() {
    WebFont.load({
      google: {
        families: ["Inter:regular,bold,italic,bolditalic"],
      },
    });
    window.addEventListener(CUSTOM_EVENTS.HYPE_TRAIN_EVENT, this.onNewEvent);
    this.currentTimeUpdateInterval = setInterval(this.updateCurrentTime, 500);
  },
  beforeUnmount() {
    //window.removeEventListener("", this.onNewEvent);
    clearInterval(this.updateCurrentTime);
  },
};
</script>

<style scoped lang="scss">
.hype-train-container {
  width: 100%;
  position: relative;
  background: white;
  border-radius: 1em;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: WidgetAppearBounce 0.5s both ease-in-out;
  .hype-train-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #9146ff;
    transition: width 0.3s ease-in-out;
  }

  .hype-train-content {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    color: black;
    .hype-train-content-body {
      flex: 1;
      .hype-train-content-header {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;
        .hype-train-content-level {
          font-weight: bold;
          text-transform: uppercase;
          background: black;
          border-radius: 99px;
          padding: 0.25rem 0.75rem;
          margin-right: 0.5rem;
          color: white;
          display: inline-flex;
        }
      }
      .hype-train-content-description {
        font-size: 0.8em;
        line-height: 1.5em;
        font-weight: 500;
        max-width: 22ch;
      }
    }
    .hype-train-content-side {
      text-align: right;
      .hype-train-content-time {
        opacity: 0.5;
        font-weight: 500;
        font-size: 0.8em;
      }
      .hype-train-content-progress {
        font-weight: bold;
        font-size: 2em;
      }
    }
  }
  .hype-train-level-up {
    background-color: #bf94ff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 2em;
    .hype-train-level-up-text {
      animation: fadeGrowText 2s ease-in-out both;
    }
    .hype-train-level-up-hype-text {
      display: flex;
      width: 100%;
      animation: slideInFromRight 5s ease-in-out both;
    }
    .hype-train-level-up-level-text {
      display: flex;
      text-align: center;
      justify-content: center;
      width: 100%;
      font-size: 1rem;
      animation: slideInFromBottom 0.5s ease-in-out both;
    }
  }
}
</style>

<style>
.hype-train-fade-enter-active,
.hype-train-fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.hype-train-fade-enter-from,
.hype-train-fade-leave-to {
  opacity: 0;
}

@keyframes fadeGrowText {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;

    transform: scale(2);
  }
}
@keyframes slideInFromBottom {
  from {
    transform: translateY(200%);
  }
  to {
  }
}
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes WidgetAppearBounce {
  0% {
    transform: scale(0);
  }

  85% {
    transform: scale(1.1);
  }

  95% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}
</style>
