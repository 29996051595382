export const GLOBAL_BADGES = [
  {
    set_id: "1979-revolution_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/7833bb6e-d20d-48ff-a58d-67fe827a4f84/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/7833bb6e-d20d-48ff-a58d-67fe827a4f84/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/7833bb6e-d20d-48ff-a58d-67fe827a4f84/3",
        title: "1979 Revolution",
        description: "1979 Revolution",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/1979%20Revolution/details",
      },
    ],
  },
  {
    set_id: "60-seconds_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/1e7252f9-7e80-4d3d-ae42-319f030cca99/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/1e7252f9-7e80-4d3d-ae42-319f030cca99/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/1e7252f9-7e80-4d3d-ae42-319f030cca99/3",
        title: "60 Seconds!",
        description: "60 Seconds!",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/60%20Seconds!/details",
      },
    ],
  },
  {
    set_id: "60-seconds_2",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/64513f7d-21dd-4a05-a699-d73761945cf9/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/64513f7d-21dd-4a05-a699-d73761945cf9/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/64513f7d-21dd-4a05-a699-d73761945cf9/3",
        title: "60 Seconds!",
        description: "60 Seconds!",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/60%20Seconds!/details",
      },
    ],
  },
  {
    set_id: "60-seconds_3",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f4306617-0f96-476f-994e-5304f81bcc6e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f4306617-0f96-476f-994e-5304f81bcc6e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f4306617-0f96-476f-994e-5304f81bcc6e/3",
        title: "60 Seconds!",
        description: "60 Seconds!",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/60%20Seconds!/details",
      },
    ],
  },
  {
    set_id: "H1Z1_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/fc71386c-86cd-11e7-a55d-43f591dc0c71/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/fc71386c-86cd-11e7-a55d-43f591dc0c71/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/fc71386c-86cd-11e7-a55d-43f591dc0c71/3",
        title: "H1Z1",
        description: "H1Z1",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/H1Z1/details",
      },
    ],
  },
  {
    set_id: "admin",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/9ef7e029-4cdf-4d4d-a0d5-e2b3fb2583fe/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/9ef7e029-4cdf-4d4d-a0d5-e2b3fb2583fe/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/9ef7e029-4cdf-4d4d-a0d5-e2b3fb2583fe/3",
        title: "Admin",
        description: "Twitch Admin",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "ambassador",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/2cbc339f-34f4-488a-ae51-efdf74f4e323/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/2cbc339f-34f4-488a-ae51-efdf74f4e323/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/2cbc339f-34f4-488a-ae51-efdf74f4e323/3",
        title: "Twitch Ambassador",
        description: "Twitch Ambassador",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/team/ambassadors",
      },
    ],
  },
  {
    set_id: "anomaly-2_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/d1d1ad54-40a6-492b-882e-dcbdce5fa81e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/d1d1ad54-40a6-492b-882e-dcbdce5fa81e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/d1d1ad54-40a6-492b-882e-dcbdce5fa81e/3",
        title: "Anomaly 2",
        description: "Anomaly 2",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Anomaly%202/details",
      },
    ],
  },
  {
    set_id: "anomaly-warzone-earth_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/858be873-fb1f-47e5-ad34-657f40d3d156/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/858be873-fb1f-47e5-ad34-657f40d3d156/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/858be873-fb1f-47e5-ad34-657f40d3d156/3",
        title: "Anomaly Warzone Earth",
        description: "Anomaly Warzone Earth",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Anomaly:%20Warzone%20Earth/details",
      },
    ],
  },
  {
    set_id: "anonymous-cheerer",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ca3db7f7-18f5-487e-a329-cd0b538ee979/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ca3db7f7-18f5-487e-a329-cd0b538ee979/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ca3db7f7-18f5-487e-a329-cd0b538ee979/3",
        title: "Anonymous Cheerer",
        description: "Anonymous Cheerer",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "artist-badge",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/4300a897-03dc-4e83-8c0e-c332fee7057f/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/4300a897-03dc-4e83-8c0e-c332fee7057f/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/4300a897-03dc-4e83-8c0e-c332fee7057f/3",
        title: "Artist",
        description: "Artist on this Channel",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "axiom-verge_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f209b747-45ee-42f6-8baf-ea7542633d10/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f209b747-45ee-42f6-8baf-ea7542633d10/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f209b747-45ee-42f6-8baf-ea7542633d10/3",
        title: "Axiom Verge",
        description: "Axiom Verge",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Axiom%20Verge/details",
      },
    ],
  },
  {
    set_id: "battlechefbrigade_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/24e32e67-33cd-4227-ad96-f0a7fc836107/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/24e32e67-33cd-4227-ad96-f0a7fc836107/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/24e32e67-33cd-4227-ad96-f0a7fc836107/3",
        title: "Battle Chef Brigade",
        description: "Battle Chef Brigade",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Battle%20Chef%20Brigade/details",
      },
    ],
  },
  {
    set_id: "battlechefbrigade_2",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ef1e96e8-a0f9-40b6-87af-2977d3c004bb/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ef1e96e8-a0f9-40b6-87af-2977d3c004bb/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ef1e96e8-a0f9-40b6-87af-2977d3c004bb/3",
        title: "Battle Chef Brigade",
        description: "Battle Chef Brigade",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Battle%20Chef%20Brigade/details",
      },
    ],
  },
  {
    set_id: "battlechefbrigade_3",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/107ebb20-4fcd-449a-9931-cd3f81b84c70/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/107ebb20-4fcd-449a-9931-cd3f81b84c70/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/107ebb20-4fcd-449a-9931-cd3f81b84c70/3",
        title: "Battle Chef Brigade",
        description: "Battle Chef Brigade",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Battle%20Chef%20Brigade/details",
      },
    ],
  },
  {
    set_id: "battlerite_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/484ebda9-f7fa-4c67-b12b-c80582f3cc61/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/484ebda9-f7fa-4c67-b12b-c80582f3cc61/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/484ebda9-f7fa-4c67-b12b-c80582f3cc61/3",
        title: "Battlerite",
        description: "Battlerite",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Battlerite/details",
      },
    ],
  },
  {
    set_id: "bits",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/73b5c3fb-24f9-4a82-a852-2f475b59411c/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/73b5c3fb-24f9-4a82-a852-2f475b59411c/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/73b5c3fb-24f9-4a82-a852-2f475b59411c/3",
        title: "cheer 1",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "100",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/09d93036-e7ce-431c-9a9e-7044297133f2/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/09d93036-e7ce-431c-9a9e-7044297133f2/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/09d93036-e7ce-431c-9a9e-7044297133f2/3",
        title: "cheer 100",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "1000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/0d85a29e-79ad-4c63-a285-3acd2c66f2ba/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/0d85a29e-79ad-4c63-a285-3acd2c66f2ba/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/0d85a29e-79ad-4c63-a285-3acd2c66f2ba/3",
        title: "cheer 1000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "10000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/68af213b-a771-4124-b6e3-9bb6d98aa732/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/68af213b-a771-4124-b6e3-9bb6d98aa732/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/68af213b-a771-4124-b6e3-9bb6d98aa732/3",
        title: "cheer 10000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "100000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/96f0540f-aa63-49e1-a8b3-259ece3bd098/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/96f0540f-aa63-49e1-a8b3-259ece3bd098/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/96f0540f-aa63-49e1-a8b3-259ece3bd098/3",
        title: "cheer 100000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "1000000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/494d1c8e-c3b2-4d88-8528-baff57c9bd3f/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/494d1c8e-c3b2-4d88-8528-baff57c9bd3f/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/494d1c8e-c3b2-4d88-8528-baff57c9bd3f/3",
        title: "cheer 1000000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "1250000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ce217209-4615-4bf8-81e3-57d06b8b9dc7/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ce217209-4615-4bf8-81e3-57d06b8b9dc7/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ce217209-4615-4bf8-81e3-57d06b8b9dc7/3",
        title: "cheer 1250000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "1500000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c4eba5b4-17a7-40a1-a668-bc1972c1e24d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c4eba5b4-17a7-40a1-a668-bc1972c1e24d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c4eba5b4-17a7-40a1-a668-bc1972c1e24d/3",
        title: "cheer 1500000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "1750000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/183f1fd8-aaf4-450c-a413-e53f839f0f82/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/183f1fd8-aaf4-450c-a413-e53f839f0f82/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/183f1fd8-aaf4-450c-a413-e53f839f0f82/3",
        title: "cheer 1750000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "200000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/4a0b90c4-e4ef-407f-84fe-36b14aebdbb6/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/4a0b90c4-e4ef-407f-84fe-36b14aebdbb6/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/4a0b90c4-e4ef-407f-84fe-36b14aebdbb6/3",
        title: "cheer 200000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "2000000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/7ea89c53-1a3b-45f9-9223-d97ae19089f2/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/7ea89c53-1a3b-45f9-9223-d97ae19089f2/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/7ea89c53-1a3b-45f9-9223-d97ae19089f2/3",
        title: "cheer 2000000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "25000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/64ca5920-c663-4bd8-bfb1-751b4caea2dd/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/64ca5920-c663-4bd8-bfb1-751b4caea2dd/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/64ca5920-c663-4bd8-bfb1-751b4caea2dd/3",
        title: "cheer 25000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "2500000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/cf061daf-d571-4811-bcc2-c55c8792bc8f/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/cf061daf-d571-4811-bcc2-c55c8792bc8f/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/cf061daf-d571-4811-bcc2-c55c8792bc8f/3",
        title: "cheer 2500000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "300000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ac13372d-2e94-41d1-ae11-ecd677f69bb6/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ac13372d-2e94-41d1-ae11-ecd677f69bb6/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ac13372d-2e94-41d1-ae11-ecd677f69bb6/3",
        title: "cheer 300000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "3000000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/5671797f-5e9f-478c-a2b5-eb086c8928cf/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/5671797f-5e9f-478c-a2b5-eb086c8928cf/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/5671797f-5e9f-478c-a2b5-eb086c8928cf/3",
        title: "cheer 3000000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "3500000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c3d218f5-1e45-419d-9c11-033a1ae54d3a/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c3d218f5-1e45-419d-9c11-033a1ae54d3a/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c3d218f5-1e45-419d-9c11-033a1ae54d3a/3",
        title: "cheer 3500000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "400000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/a8f393af-76e6-4aa2-9dd0-7dcc1c34f036/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/a8f393af-76e6-4aa2-9dd0-7dcc1c34f036/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/a8f393af-76e6-4aa2-9dd0-7dcc1c34f036/3",
        title: "cheer 400000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "4000000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/79fe642a-87f3-40b1-892e-a341747b6e08/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/79fe642a-87f3-40b1-892e-a341747b6e08/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/79fe642a-87f3-40b1-892e-a341747b6e08/3",
        title: "cheer 4000000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "4500000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/736d4156-ac67-4256-a224-3e6e915436db/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/736d4156-ac67-4256-a224-3e6e915436db/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/736d4156-ac67-4256-a224-3e6e915436db/3",
        title: "cheer 4500000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "5000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/57cd97fc-3e9e-4c6d-9d41-60147137234e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/57cd97fc-3e9e-4c6d-9d41-60147137234e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/57cd97fc-3e9e-4c6d-9d41-60147137234e/3",
        title: "cheer 5000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "50000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/62310ba7-9916-4235-9eba-40110d67f85d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/62310ba7-9916-4235-9eba-40110d67f85d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/62310ba7-9916-4235-9eba-40110d67f85d/3",
        title: "cheer 50000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "500000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f6932b57-6a6e-4062-a770-dfbd9f4302e5/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f6932b57-6a6e-4062-a770-dfbd9f4302e5/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f6932b57-6a6e-4062-a770-dfbd9f4302e5/3",
        title: "cheer 500000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "5000000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/3f085f85-8d15-4a03-a829-17fca7bf1bc2/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/3f085f85-8d15-4a03-a829-17fca7bf1bc2/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/3f085f85-8d15-4a03-a829-17fca7bf1bc2/3",
        title: "cheer 5000000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "600000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/4d908059-f91c-4aef-9acb-634434f4c32e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/4d908059-f91c-4aef-9acb-634434f4c32e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/4d908059-f91c-4aef-9acb-634434f4c32e/3",
        title: "cheer 600000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "700000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/a1d2a824-f216-4b9f-9642-3de8ed370957/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/a1d2a824-f216-4b9f-9642-3de8ed370957/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/a1d2a824-f216-4b9f-9642-3de8ed370957/3",
        title: "cheer 700000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "75000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ce491fa4-b24f-4f3b-b6ff-44b080202792/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ce491fa4-b24f-4f3b-b6ff-44b080202792/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ce491fa4-b24f-4f3b-b6ff-44b080202792/3",
        title: "cheer 75000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "800000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/5ec2ee3e-5633-4c2a-8e77-77473fe409e6/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/5ec2ee3e-5633-4c2a-8e77-77473fe409e6/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/5ec2ee3e-5633-4c2a-8e77-77473fe409e6/3",
        title: "cheer 800000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "900000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/088c58c6-7c38-45ba-8f73-63ef24189b84/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/088c58c6-7c38-45ba-8f73-63ef24189b84/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/088c58c6-7c38-45ba-8f73-63ef24189b84/3",
        title: "cheer 900000",
        description: " ",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
    ],
  },
  {
    set_id: "bits-charity",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/a539dc18-ae19-49b0-98c4-8391a594332b/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/a539dc18-ae19-49b0-98c4-8391a594332b/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/a539dc18-ae19-49b0-98c4-8391a594332b/3",
        title: "Direct Relief - Charity 2018",
        description:
          "Supported their favorite streamer during the 2018 Blizzard of Bits",
        click_action: "visit_url",
        click_url: "https://link.twitch.tv/blizzardofbits",
      },
    ],
  },
  {
    set_id: "bits-leader",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/8bedf8c3-7a6d-4df2-b62f-791b96a5dd31/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/8bedf8c3-7a6d-4df2-b62f-791b96a5dd31/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/8bedf8c3-7a6d-4df2-b62f-791b96a5dd31/3",
        title: "Bits Leader 1",
        description: "Ranked as a top cheerer on this channel",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f04baac7-9141-4456-a0e7-6301bcc34138/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f04baac7-9141-4456-a0e7-6301bcc34138/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f04baac7-9141-4456-a0e7-6301bcc34138/3",
        title: "Bits Leader 2",
        description: "Ranked as a top cheerer on this channel",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
      {
        id: "3",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f1d2aab6-b647-47af-965b-84909cf303aa/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f1d2aab6-b647-47af-965b-84909cf303aa/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f1d2aab6-b647-47af-965b-84909cf303aa/3",
        title: "Bits Leader 3",
        description: "Ranked as a top cheerer on this channel",
        click_action: "visit_url",
        click_url: "https://bits.twitch.tv",
      },
    ],
  },
  {
    set_id: "brawlhalla_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/bf6d6579-ab02-4f0a-9f64-a51c37040858/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/bf6d6579-ab02-4f0a-9f64-a51c37040858/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/bf6d6579-ab02-4f0a-9f64-a51c37040858/3",
        title: "Brawlhalla",
        description: "Brawlhalla",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Brawlhalla/details",
      },
    ],
  },
  {
    set_id: "broadcaster",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/3",
        title: "Broadcaster",
        description: "Broadcaster",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "broken-age_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/56885ed2-9a09-4c8e-8131-3eb9ec15af94/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/56885ed2-9a09-4c8e-8131-3eb9ec15af94/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/56885ed2-9a09-4c8e-8131-3eb9ec15af94/3",
        title: "Broken Age",
        description: "Broken Age",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Broken%20Age/details",
      },
    ],
  },
  {
    set_id: "bubsy-the-woolies_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c8129382-1f4e-4d15-a8d2-48bdddba9b81/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c8129382-1f4e-4d15-a8d2-48bdddba9b81/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c8129382-1f4e-4d15-a8d2-48bdddba9b81/3",
        title: "Bubsy: The Woolies Strike Back",
        description: "Bubsy: The Woolies Strike Back",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Bubsy:%20The%20Woolies%20Strike%20Back/details",
      },
    ],
  },
  {
    set_id: "chatter-cs-go-2022",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/57b6bd6b-a1b5-4204-9e6c-eb8ed5831603/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/57b6bd6b-a1b5-4204-9e6c-eb8ed5831603/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/57b6bd6b-a1b5-4204-9e6c-eb8ed5831603/3",
        title: "CS:GO Week Brazil 2022",
        description: "Chatted during CS:GO Week Brazil 2022",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "clip-champ",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f38976e0-ffc9-11e7-86d6-7f98b26a9d79/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f38976e0-ffc9-11e7-86d6-7f98b26a9d79/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f38976e0-ffc9-11e7-86d6-7f98b26a9d79/3",
        title: "Power Clipper",
        description: "Power Clipper",
        click_action: "visit_url",
        click_url:
          "https://help.twitch.tv/customer/portal/articles/2918323-clip-champs-guide",
      },
    ],
  },
  {
    set_id: "creator-cs-go-2022",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/a2ea6df9-ac0a-4956-bfe9-e931f50b94fa/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/a2ea6df9-ac0a-4956-bfe9-e931f50b94fa/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/a2ea6df9-ac0a-4956-bfe9-e931f50b94fa/3",
        title: "CS:GO Week Brazil 2022",
        description: "Streamed during CS:GO Week Brazil 2022",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "cuphead_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/4384659a-a2e3-11e7-a564-87f6b1288bab/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/4384659a-a2e3-11e7-a564-87f6b1288bab/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/4384659a-a2e3-11e7-a564-87f6b1288bab/3",
        title: "Cuphead",
        description: "Cuphead",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Cuphead/details",
      },
    ],
  },
  {
    set_id: "darkest-dungeon_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/52a98ddd-cc79-46a8-9fe3-30f8c719bc2d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/52a98ddd-cc79-46a8-9fe3-30f8c719bc2d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/52a98ddd-cc79-46a8-9fe3-30f8c719bc2d/3",
        title: "Darkest Dungeon",
        description: "Darkest Dungeon",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Darkest%20Dungeon/details",
      },
    ],
  },
  {
    set_id: "deceit_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/b14fef48-4ff9-4063-abf6-579489234fe9/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/b14fef48-4ff9-4063-abf6-579489234fe9/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/b14fef48-4ff9-4063-abf6-579489234fe9/3",
        title: "Deceit",
        description: "Deceit",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Deceit/details",
      },
    ],
  },
  {
    set_id: "devil-may-cry-hd_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/633877d4-a91c-4c36-b75b-803f82b1352f/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/633877d4-a91c-4c36-b75b-803f82b1352f/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/633877d4-a91c-4c36-b75b-803f82b1352f/3",
        title: "Devil May Cry HD Collection",
        description: "Devil May Cry HD Collection",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Devil%20May%20Cry%20HD%20Collection/details",
      },
    ],
  },
  {
    set_id: "devil-may-cry-hd_2",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/408548fe-aa74-4d53-b5e9-960103d9b865/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/408548fe-aa74-4d53-b5e9-960103d9b865/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/408548fe-aa74-4d53-b5e9-960103d9b865/3",
        title: "Devil May Cry HD Collection",
        description: "Devil May Cry HD Collection",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Devil%20May%20Cry%20HD%20Collection/details",
      },
    ],
  },
  {
    set_id: "devil-may-cry-hd_3",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/df84c5bf-8d66-48e2-b9fb-c014cc9b3945/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/df84c5bf-8d66-48e2-b9fb-c014cc9b3945/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/df84c5bf-8d66-48e2-b9fb-c014cc9b3945/3",
        title: "Devil May Cry HD Collection",
        description: "Devil May Cry HD Collection",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Devil%20May%20Cry%20HD%20Collection/details",
      },
    ],
  },
  {
    set_id: "devil-may-cry-hd_4",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/af836b94-8ffd-4c0a-b7d8-a92fad5e3015/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/af836b94-8ffd-4c0a-b7d8-a92fad5e3015/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/af836b94-8ffd-4c0a-b7d8-a92fad5e3015/3",
        title: "Devil May Cry HD Collection",
        description: "Devil May Cry HD Collection",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Devil%20May%20Cry%20HD%20Collection/details",
      },
    ],
  },
  {
    set_id: "devilian_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/3cb92b57-1eef-451c-ac23-4d748128b2c5/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/3cb92b57-1eef-451c-ac23-4d748128b2c5/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/3cb92b57-1eef-451c-ac23-4d748128b2c5/3",
        title: "Devilian",
        description: "Devilian",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Devilian/details",
      },
    ],
  },
  {
    set_id: "duelyst_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/7d9c12f4-a2ac-4e88-8026-d1a330468282/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/7d9c12f4-a2ac-4e88-8026-d1a330468282/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/7d9c12f4-a2ac-4e88-8026-d1a330468282/3",
        title: "Duelyst",
        description: "Duelyst",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Duelyst/details",
      },
    ],
  },
  {
    set_id: "duelyst_2",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/1938acd3-2d18-471d-b1af-44f2047c033c/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/1938acd3-2d18-471d-b1af-44f2047c033c/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/1938acd3-2d18-471d-b1af-44f2047c033c/3",
        title: "Duelyst",
        description: "Duelyst",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Duelyst/details",
      },
    ],
  },
  {
    set_id: "duelyst_3",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/344c07fc-1632-47c6-9785-e62562a6b760/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/344c07fc-1632-47c6-9785-e62562a6b760/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/344c07fc-1632-47c6-9785-e62562a6b760/3",
        title: "Duelyst",
        description: "Duelyst",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Duelyst/details",
      },
    ],
  },
  {
    set_id: "duelyst_4",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/39e717a8-00bc-49cc-b6d4-3ea91ee1be25/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/39e717a8-00bc-49cc-b6d4-3ea91ee1be25/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/39e717a8-00bc-49cc-b6d4-3ea91ee1be25/3",
        title: "Duelyst",
        description: "Duelyst",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Duelyst/details",
      },
    ],
  },
  {
    set_id: "duelyst_5",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/290419b6-484a-47da-ad14-a99d6581f758/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/290419b6-484a-47da-ad14-a99d6581f758/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/290419b6-484a-47da-ad14-a99d6581f758/3",
        title: "Duelyst",
        description: "Duelyst",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Duelyst/details",
      },
    ],
  },
  {
    set_id: "duelyst_6",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c5e54a4b-0bf1-463a-874a-38524579aed0/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c5e54a4b-0bf1-463a-874a-38524579aed0/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c5e54a4b-0bf1-463a-874a-38524579aed0/3",
        title: "Duelyst",
        description: "Duelyst",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Duelyst/details",
      },
    ],
  },
  {
    set_id: "duelyst_7",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/cf508179-3183-4987-97e0-56ca44babb9f/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/cf508179-3183-4987-97e0-56ca44babb9f/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/cf508179-3183-4987-97e0-56ca44babb9f/3",
        title: "Duelyst",
        description: "Duelyst",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Duelyst/details",
      },
    ],
  },
  {
    set_id: "enter-the-gungeon_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/53c9af0b-84f6-4f9d-8c80-4bc51321a37d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/53c9af0b-84f6-4f9d-8c80-4bc51321a37d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/53c9af0b-84f6-4f9d-8c80-4bc51321a37d/3",
        title: "Enter The Gungeon",
        description: "Enter The Gungeon",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Enter%20the%20Gungeon/details",
      },
    ],
  },
  {
    set_id: "eso_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/18647a68-a35f-48d7-bf97-ae5deb6b277d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/18647a68-a35f-48d7-bf97-ae5deb6b277d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/18647a68-a35f-48d7-bf97-ae5deb6b277d/3",
        title: "Elder Scrolls Online",
        description: "Elder Scrolls Online",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "extension",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ea8b0f8c-aa27-11e8-ba0c-1370ffff3854/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ea8b0f8c-aa27-11e8-ba0c-1370ffff3854/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ea8b0f8c-aa27-11e8-ba0c-1370ffff3854/3",
        title: "Extension",
        description: "Extension",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "firewatch_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/b6bf4889-4902-49e2-9658-c0132e71c9c4/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/b6bf4889-4902-49e2-9658-c0132e71c9c4/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/b6bf4889-4902-49e2-9658-c0132e71c9c4/3",
        title: "Firewatch",
        description: "Firewatch",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Firewatch/details",
      },
    ],
  },
  {
    set_id: "founder",
    versions: [
      {
        id: "0",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/511b78a9-ab37-472f-9569-457753bbe7d3/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/511b78a9-ab37-472f-9569-457753bbe7d3/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/511b78a9-ab37-472f-9569-457753bbe7d3/3",
        title: "Founder",
        description: "Founder",
        click_action: "visit_url",
        click_url: "https://help.twitch.tv/s/article/founders-badge",
      },
    ],
  },
  {
    set_id: "frozen-cortext_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/2015f087-01b5-4a01-a2bb-ecb4d6be5240/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/2015f087-01b5-4a01-a2bb-ecb4d6be5240/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/2015f087-01b5-4a01-a2bb-ecb4d6be5240/3",
        title: "Frozen Cortext",
        description: "Frozen Cortext",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Frozen%20Cortex/details",
      },
    ],
  },
  {
    set_id: "frozen-synapse_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/d4bd464d-55ea-4238-a11d-744f034e2375/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/d4bd464d-55ea-4238-a11d-744f034e2375/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/d4bd464d-55ea-4238-a11d-744f034e2375/3",
        title: "Frozen Synapse",
        description: "Frozen Synapse",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Frozen%20Synapse/details",
      },
    ],
  },
  {
    set_id: "game-developer",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/85856a4a-eb7d-4e26-a43e-d204a977ade4/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/85856a4a-eb7d-4e26-a43e-d204a977ade4/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/85856a4a-eb7d-4e26-a43e-d204a977ade4/3",
        title: "Game Developer",
        description: "Game Developer for:",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "getting-over-it_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/8d4e178c-81ec-4c71-af68-745b40733984/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/8d4e178c-81ec-4c71-af68-745b40733984/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/8d4e178c-81ec-4c71-af68-745b40733984/3",
        title: "Getting Over It",
        description: "Getting Over It",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Getting%20Over%20It/details",
      },
    ],
  },
  {
    set_id: "getting-over-it_2",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/bb620b42-e0e1-4373-928e-d4a732f99ccb/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/bb620b42-e0e1-4373-928e-d4a732f99ccb/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/bb620b42-e0e1-4373-928e-d4a732f99ccb/3",
        title: "Getting Over It",
        description: "Getting Over It",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Getting%20Over%20It/details",
      },
    ],
  },
  {
    set_id: "glhf-pledge",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/3158e758-3cb4-43c5-94b3-7639810451c5/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/3158e758-3cb4-43c5-94b3-7639810451c5/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/3158e758-3cb4-43c5-94b3-7639810451c5/3",
        title: "GLHF Pledge",
        description:
          "Signed the GLHF pledge in support for inclusive gaming communities",
        click_action: "visit_url",
        click_url: "https://www.anykey.org/pledge",
      },
    ],
  },
  {
    set_id: "glitchcon2020",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/1d4b03b9-51ea-42c9-8f29-698e3c85be3d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/1d4b03b9-51ea-42c9-8f29-698e3c85be3d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/1d4b03b9-51ea-42c9-8f29-698e3c85be3d/3",
        title: "GlitchCon 2020",
        description: "Earned for Watching Glitchcon 2020",
        click_action: "visit_url",
        click_url: "https://www.twitchcon.com/",
      },
    ],
  },
  {
    set_id: "global_mod",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/9384c43e-4ce7-4e94-b2a1-b93656896eba/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/9384c43e-4ce7-4e94-b2a1-b93656896eba/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/9384c43e-4ce7-4e94-b2a1-b93656896eba/3",
        title: "Global Moderator",
        description: "Global Moderator",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "gold-pixel-heart",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/1687873b-cf38-412c-aad3-f9a4ce17f8b6/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/1687873b-cf38-412c-aad3-f9a4ce17f8b6/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/1687873b-cf38-412c-aad3-f9a4ce17f8b6/3",
        title: "Gold Pixel Heart",
        description:
          "Thank you for donating via the Twitch Charity tool during Twitch Together for Good 2023!",
        click_action: "visit_url",
        click_url: "https://help.twitch.tv/s/article/twitch-charity",
      },
    ],
  },
  {
    set_id: "heavy-bullets_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/fc83b76b-f8b2-4519-9f61-6faf84eef4cd/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/fc83b76b-f8b2-4519-9f61-6faf84eef4cd/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/fc83b76b-f8b2-4519-9f61-6faf84eef4cd/3",
        title: "Heavy Bullets",
        description: "Heavy Bullets",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Heavy%20Bullets/details",
      },
    ],
  },
  {
    set_id: "hello_neighbor_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/030cab2c-5d14-11e7-8d91-43a5a4306286/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/030cab2c-5d14-11e7-8d91-43a5a4306286/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/030cab2c-5d14-11e7-8d91-43a5a4306286/3",
        title: "Hello Neighbor",
        description: "Hello Neighbor",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Hello%20Neighbor/details",
      },
    ],
  },
  {
    set_id: "hype-train",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/fae4086c-3190-44d4-83c8-8ef0cbe1a515/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/fae4086c-3190-44d4-83c8-8ef0cbe1a515/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/fae4086c-3190-44d4-83c8-8ef0cbe1a515/3",
        title: "Current Hype Train Conductor",
        description: "Top supporter during the most recent hype train",
        click_action: "visit_url",
        click_url: "https://help.twitch.tv/s/article/hype-train-guide",
      },
      {
        id: "2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/9c8d038a-3a29-45ea-96d4-5031fb1a7a81/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/9c8d038a-3a29-45ea-96d4-5031fb1a7a81/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/9c8d038a-3a29-45ea-96d4-5031fb1a7a81/3",
        title: "Former Hype Train Conductor",
        description: "Top supporter during prior hype trains",
        click_action: "visit_url",
        click_url: "https://help.twitch.tv/s/article/hype-train-guide",
      },
    ],
  },
  {
    set_id: "innerspace_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/97532ccd-6a07-42b5-aecf-3458b6b3ebea/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/97532ccd-6a07-42b5-aecf-3458b6b3ebea/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/97532ccd-6a07-42b5-aecf-3458b6b3ebea/3",
        title: "Innerspace",
        description: "Innerspace",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Innerspace/details",
      },
    ],
  },
  {
    set_id: "innerspace_2",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/fc7d6018-657a-40e4-9246-0acdc85886d1/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/fc7d6018-657a-40e4-9246-0acdc85886d1/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/fc7d6018-657a-40e4-9246-0acdc85886d1/3",
        title: "Innerspace",
        description: "Innerspace",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Innerspace/details",
      },
    ],
  },
  {
    set_id: "jackbox-party-pack_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/0f964fc1-f439-485f-a3c0-905294ee70e8/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/0f964fc1-f439-485f-a3c0-905294ee70e8/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/0f964fc1-f439-485f-a3c0-905294ee70e8/3",
        title: "Jackbox Party Pack",
        description: "Jackbox Party Pack",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/The%20Jackbox%20Party%20Pack/details",
      },
    ],
  },
  {
    set_id: "kingdom-new-lands_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/e3c2a67e-ef80-4fe3-ae41-b933cd11788a/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/e3c2a67e-ef80-4fe3-ae41-b933cd11788a/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/e3c2a67e-ef80-4fe3-ae41-b933cd11788a/3",
        title: "Kingdom: New Lands",
        description: "Kingdom: New Lands",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Kingdom:%20New%20Lands/details",
      },
    ],
  },
  {
    set_id: "moderator",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/3",
        title: "Moderator",
        description: "Moderator",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "moments",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/bf370830-d79a-497b-81c6-a365b2b60dda/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/bf370830-d79a-497b-81c6-a365b2b60dda/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/bf370830-d79a-497b-81c6-a365b2b60dda/3",
        title: "Moments Badge - Tier 1",
        description:
          "Earned for being a part of at least 1 moment on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "10",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/9c13f2b6-69cd-4537-91b4-4a8bd8b6b1fd/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/9c13f2b6-69cd-4537-91b4-4a8bd8b6b1fd/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/9c13f2b6-69cd-4537-91b4-4a8bd8b6b1fd/3",
        title: "Moments Badge - Tier 10",
        description:
          "Earned for being a part of at least 75 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "11",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/7573e7a2-0f1f-4508-b833-d822567a1e03/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/7573e7a2-0f1f-4508-b833-d822567a1e03/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/7573e7a2-0f1f-4508-b833-d822567a1e03/3",
        title: "Moments Badge - Tier 11",
        description:
          "Earned for being a part of at least 90 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "12",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f2c91d14-85c8-434b-a6c0-6d7930091150/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f2c91d14-85c8-434b-a6c0-6d7930091150/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f2c91d14-85c8-434b-a6c0-6d7930091150/3",
        title: "Moments Badge - Tier 12",
        description:
          "Earned for being a part of at least 105 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "13",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/35eb3395-a1d3-4170-969a-86402ecfb11a/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/35eb3395-a1d3-4170-969a-86402ecfb11a/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/35eb3395-a1d3-4170-969a-86402ecfb11a/3",
        title: "Moments Badge - Tier 13",
        description:
          "Earned for being a part of at least 120 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "14",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/cb40eb03-1015-45ba-8793-51c66a24a3d5/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/cb40eb03-1015-45ba-8793-51c66a24a3d5/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/cb40eb03-1015-45ba-8793-51c66a24a3d5/3",
        title: "Moments Badge - Tier 14",
        description:
          "Earned for being a part of at least 140 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "15",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/b241d667-280b-4183-96ae-2d0053631186/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/b241d667-280b-4183-96ae-2d0053631186/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/b241d667-280b-4183-96ae-2d0053631186/3",
        title: "Moments Badge - Tier 15",
        description:
          "Earned for being a part of at least 160 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "16",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/5684d1bc-8132-4a4f-850c-18d3c5bd04f3/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/5684d1bc-8132-4a4f-850c-18d3c5bd04f3/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/5684d1bc-8132-4a4f-850c-18d3c5bd04f3/3",
        title: "Moments Badge - Tier 16",
        description:
          "Earned for being a part of at least 180 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "17",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/3b08c1ee-0f77-451b-9226-b5b22d7f023c/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/3b08c1ee-0f77-451b-9226-b5b22d7f023c/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/3b08c1ee-0f77-451b-9226-b5b22d7f023c/3",
        title: "Moments Badge - Tier 17",
        description:
          "Earned for being a part of at least 200 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "18",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/14057e75-080c-42da-a412-6232c6f33b68/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/14057e75-080c-42da-a412-6232c6f33b68/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/14057e75-080c-42da-a412-6232c6f33b68/3",
        title: "Moments Badge - Tier 18",
        description:
          "Earned for being a part of at least 225 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "19",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/6100cc6f-6b4b-4a3d-a55b-a5b34edb5ea1/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/6100cc6f-6b4b-4a3d-a55b-a5b34edb5ea1/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/6100cc6f-6b4b-4a3d-a55b-a5b34edb5ea1/3",
        title: "Moments Badge - Tier 19",
        description:
          "Earned for being a part of at least 250 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/fc46b10c-5b45-43fd-81ad-d5cb0de6d2f4/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/fc46b10c-5b45-43fd-81ad-d5cb0de6d2f4/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/fc46b10c-5b45-43fd-81ad-d5cb0de6d2f4/3",
        title: "Moments Badge - Tier 2",
        description:
          "Earned for being a part of at least 5 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "20",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/43399796-e74c-4741-a975-56202f0af30e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/43399796-e74c-4741-a975-56202f0af30e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/43399796-e74c-4741-a975-56202f0af30e/3",
        title: "Moments Badge - Tier 20",
        description:
          "Earned for being a part of at least 275 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "3",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/d08658d7-205f-4f75-ad44-8c6e0acd8ef6/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/d08658d7-205f-4f75-ad44-8c6e0acd8ef6/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/d08658d7-205f-4f75-ad44-8c6e0acd8ef6/3",
        title: "Moments Badge - Tier 3",
        description:
          "Earned for being a part of at least 10 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "4",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/fe5b5ddc-93e7-4aaf-9b3e-799cd41808b1/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/fe5b5ddc-93e7-4aaf-9b3e-799cd41808b1/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/fe5b5ddc-93e7-4aaf-9b3e-799cd41808b1/3",
        title: "Moments Badge - Tier 4",
        description:
          "Earned for being a part of at least 15 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "5",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c8a0d95a-856e-4097-9fc0-7765300a4f58/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c8a0d95a-856e-4097-9fc0-7765300a4f58/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c8a0d95a-856e-4097-9fc0-7765300a4f58/3",
        title: "Moments Badge - Tier 5",
        description:
          "Earned for being a part of at least 20 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "6",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f9e3b4e4-200e-4045-bd71-3a6b480c23ae/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f9e3b4e4-200e-4045-bd71-3a6b480c23ae/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f9e3b4e4-200e-4045-bd71-3a6b480c23ae/3",
        title: "Moments Badge - Tier 6",
        description:
          "Earned for being a part of at least 30 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "7",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/a90a26a4-fdf7-4ac3-a782-76a413da16c1/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/a90a26a4-fdf7-4ac3-a782-76a413da16c1/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/a90a26a4-fdf7-4ac3-a782-76a413da16c1/3",
        title: "Moments Badge - Tier 7",
        description:
          "Earned for being a part of at least 40 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "8",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f22286cd-6aa3-42ce-b3fb-10f5d18c4aa0/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f22286cd-6aa3-42ce-b3fb-10f5d18c4aa0/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f22286cd-6aa3-42ce-b3fb-10f5d18c4aa0/3",
        title: "Moments Badge - Tier 8",
        description:
          "Earned for being a part of at least 50 moments on a channel",
        click_action: null,
        click_url: null,
      },
      {
        id: "9",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/5cb2e584-1efd-469b-ab1d-4d1b59a944e7/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/5cb2e584-1efd-469b-ab1d-4d1b59a944e7/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/5cb2e584-1efd-469b-ab1d-4d1b59a944e7/3",
        title: "Moments Badge - Tier 9",
        description:
          "Earned for being a part of at least 60 moments on a channel",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "no_audio",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/aef2cd08-f29b-45a1-8c12-d44d7fd5e6f0/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/aef2cd08-f29b-45a1-8c12-d44d7fd5e6f0/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/aef2cd08-f29b-45a1-8c12-d44d7fd5e6f0/3",
        title: "Watching without audio",
        description:
          "Individuals with unreliable or no sound can select this badge",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "no_video",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/199a0dba-58f3-494e-a7fc-1fa0a1001fb8/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/199a0dba-58f3-494e-a7fc-1fa0a1001fb8/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/199a0dba-58f3-494e-a7fc-1fa0a1001fb8/3",
        title: "Listening only",
        description:
          "Individuals with unreliable or no video can select this badge",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "okhlos_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/dc088bd6-8965-4907-a1a2-c0ba83874a7d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/dc088bd6-8965-4907-a1a2-c0ba83874a7d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/dc088bd6-8965-4907-a1a2-c0ba83874a7d/3",
        title: "Okhlos",
        description: "Okhlos",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Okhlos/details",
      },
    ],
  },
  {
    set_id: "overwatch-league-insider_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/51e9e0aa-12e3-48ce-b961-421af0787dad/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/51e9e0aa-12e3-48ce-b961-421af0787dad/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/51e9e0aa-12e3-48ce-b961-421af0787dad/3",
        title: "OWL All-Access Pass 2018",
        description: "OWL All-Access Pass 2018",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/overwatchleague",
      },
    ],
  },
  {
    set_id: "overwatch-league-insider_2018B",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/34ec1979-d9bb-4706-ad15-464de814a79d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/34ec1979-d9bb-4706-ad15-464de814a79d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/34ec1979-d9bb-4706-ad15-464de814a79d/3",
        title: "OWL All-Access Pass 2018",
        description: "OWL All-Access Pass 2018",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/overwatchleague",
      },
    ],
  },
  {
    set_id: "overwatch-league-insider_2019A",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ca980da1-3639-48a6-95a3-a03b002eb0e5/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ca980da1-3639-48a6-95a3-a03b002eb0e5/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ca980da1-3639-48a6-95a3-a03b002eb0e5/3",
        title: "OWL All-Access Pass 2019",
        description: "OWL All-Access Pass 2019",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/overwatchleague",
      },
      {
        id: "2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ab7fa7a7-c2d9-403f-9f33-215b29b43ce4/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ab7fa7a7-c2d9-403f-9f33-215b29b43ce4/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ab7fa7a7-c2d9-403f-9f33-215b29b43ce4/3",
        title: "OWL All-Access Pass 2019",
        description: "OWL All-Access Pass 2019",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/overwatchleague",
      },
    ],
  },
  {
    set_id: "overwatch-league-insider_2019B",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c5860811-d714-4413-9433-d6b1c9fc803c/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c5860811-d714-4413-9433-d6b1c9fc803c/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c5860811-d714-4413-9433-d6b1c9fc803c/3",
        title: "OWL All-Access Pass 2019",
        description: "OWL All-Access Pass 2019",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/overwatchleague",
      },
      {
        id: "2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/75f05d4b-3042-415c-8b0b-e87620a24daf/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/75f05d4b-3042-415c-8b0b-e87620a24daf/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/75f05d4b-3042-415c-8b0b-e87620a24daf/3",
        title: "OWL All-Access Pass 2019",
        description: "OWL All-Access Pass 2019",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/overwatchleague",
      },
      {
        id: "3",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/765a0dcf-2a94-43ff-9b9c-ef6c209b90cd/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/765a0dcf-2a94-43ff-9b9c-ef6c209b90cd/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/765a0dcf-2a94-43ff-9b9c-ef6c209b90cd/3",
        title: "OWL All-Access Pass 2019",
        description: "OWL All-Access Pass 2019",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/overwatchleague",
      },
      {
        id: "4",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/a8ae0ccd-783d-460d-93ee-57c485c558a6/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/a8ae0ccd-783d-460d-93ee-57c485c558a6/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/a8ae0ccd-783d-460d-93ee-57c485c558a6/3",
        title: "OWL All-Access Pass 2019",
        description: "OWL All-Access Pass 2019",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/overwatchleague",
      },
      {
        id: "5",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/be87fd6d-1560-4e33-9ba4-2401b58d901f/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/be87fd6d-1560-4e33-9ba4-2401b58d901f/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/be87fd6d-1560-4e33-9ba4-2401b58d901f/3",
        title: "OWL All-Access Pass 2019",
        description: "OWL All-Access Pass 2019",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/overwatchleague",
      },
    ],
  },
  {
    set_id: "partner",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/3",
        title: "Verified",
        description: "Verified",
        click_action: "visit_url",
        click_url:
          "https://blog.twitch.tv/2017/04/24/the-verified-badge-is-here-13381bc05735",
      },
    ],
  },
  {
    set_id: "power-rangers",
    versions: [
      {
        id: "0",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/9edf3e7f-62e4-40f5-86ab-7a646b10f1f0/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/9edf3e7f-62e4-40f5-86ab-7a646b10f1f0/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/9edf3e7f-62e4-40f5-86ab-7a646b10f1f0/3",
        title: "Black Ranger",
        description: "Black Ranger",
        click_action: null,
        click_url: null,
      },
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/1eeae8fe-5bc6-44ed-9c88-fb84d5e0df52/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/1eeae8fe-5bc6-44ed-9c88-fb84d5e0df52/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/1eeae8fe-5bc6-44ed-9c88-fb84d5e0df52/3",
        title: "Blue Ranger",
        description: "Blue Ranger",
        click_action: null,
        click_url: null,
      },
      {
        id: "2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/21bbcd6d-1751-4d28-a0c3-0b72453dd823/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/21bbcd6d-1751-4d28-a0c3-0b72453dd823/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/21bbcd6d-1751-4d28-a0c3-0b72453dd823/3",
        title: "Green Ranger",
        description: "Green Ranger",
        click_action: null,
        click_url: null,
      },
      {
        id: "3",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/5c58cb40-9028-4d16-af67-5bc0c18b745e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/5c58cb40-9028-4d16-af67-5bc0c18b745e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/5c58cb40-9028-4d16-af67-5bc0c18b745e/3",
        title: "Pink Ranger",
        description: "Pink Ranger",
        click_action: null,
        click_url: null,
      },
      {
        id: "4",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/8843d2de-049f-47d5-9794-b6517903db61/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/8843d2de-049f-47d5-9794-b6517903db61/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/8843d2de-049f-47d5-9794-b6517903db61/3",
        title: "Red Ranger",
        description: "Red Ranger",
        click_action: null,
        click_url: null,
      },
      {
        id: "5",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/06c85e34-477e-4939-9537-fd9978976042/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/06c85e34-477e-4939-9537-fd9978976042/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/06c85e34-477e-4939-9537-fd9978976042/3",
        title: "White Ranger",
        description: "White Ranger",
        click_action: null,
        click_url: null,
      },
      {
        id: "6",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/d6dca630-1ca4-48de-94e7-55ed0a24d8d1/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/d6dca630-1ca4-48de-94e7-55ed0a24d8d1/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/d6dca630-1ca4-48de-94e7-55ed0a24d8d1/3",
        title: "Yellow Ranger",
        description: "Yellow Ranger",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "predictions",
    versions: [
      {
        id: "blue-1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/e33d8b46-f63b-4e67-996d-4a7dcec0ad33/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/e33d8b46-f63b-4e67-996d-4a7dcec0ad33/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/e33d8b46-f63b-4e67-996d-4a7dcec0ad33/3",
        title: "Predicted Blue (1)",
        description: "Predicted Outcome One",
        click_action: null,
        click_url: null,
      },
      {
        id: "blue-10",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/072ae906-ecf7-44f1-ac69-a5b2261d8892/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/072ae906-ecf7-44f1-ac69-a5b2261d8892/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/072ae906-ecf7-44f1-ac69-a5b2261d8892/3",
        title: "Predicted Blue (10)",
        description: "Predicted Outcome Ten",
        click_action: null,
        click_url: null,
      },
      {
        id: "blue-2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ffdda3fe-8012-4db3-981e-7a131402b057/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ffdda3fe-8012-4db3-981e-7a131402b057/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ffdda3fe-8012-4db3-981e-7a131402b057/3",
        title: "Predicted Blue (2)",
        description: "Predicted Outcome Two",
        click_action: null,
        click_url: null,
      },
      {
        id: "blue-3",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f2ab9a19-8ef7-4f9f-bd5d-9cf4e603f845/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f2ab9a19-8ef7-4f9f-bd5d-9cf4e603f845/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f2ab9a19-8ef7-4f9f-bd5d-9cf4e603f845/3",
        title: "Predicted Blue (3)",
        description: "Predicted Outcome Three",
        click_action: null,
        click_url: null,
      },
      {
        id: "blue-4",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/df95317d-9568-46de-a421-a8520edb9349/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/df95317d-9568-46de-a421-a8520edb9349/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/df95317d-9568-46de-a421-a8520edb9349/3",
        title: "Predicted Blue (4)",
        description: "Predicted Outcome Four",
        click_action: null,
        click_url: null,
      },
      {
        id: "blue-5",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/88758be8-de09-479b-9383-e3bb6d9e6f06/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/88758be8-de09-479b-9383-e3bb6d9e6f06/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/88758be8-de09-479b-9383-e3bb6d9e6f06/3",
        title: "Predicted Blue (5)",
        description: "Predicted Outcome Five",
        click_action: null,
        click_url: null,
      },
      {
        id: "blue-6",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/46b1537e-d8b0-4c0d-8fba-a652e57b9df0/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/46b1537e-d8b0-4c0d-8fba-a652e57b9df0/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/46b1537e-d8b0-4c0d-8fba-a652e57b9df0/3",
        title: "Predicted Blue (6)",
        description: "Predicted Outcome Six",
        click_action: null,
        click_url: null,
      },
      {
        id: "blue-7",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/07cd34b2-c6a1-45f5-8d8a-131e3c8b2279/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/07cd34b2-c6a1-45f5-8d8a-131e3c8b2279/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/07cd34b2-c6a1-45f5-8d8a-131e3c8b2279/3",
        title: "Predicted Blue (7)",
        description: "Predicted Outcome Seven",
        click_action: null,
        click_url: null,
      },
      {
        id: "blue-8",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/4416dfd7-db97-44a0-98e7-40b4e250615e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/4416dfd7-db97-44a0-98e7-40b4e250615e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/4416dfd7-db97-44a0-98e7-40b4e250615e/3",
        title: "Predicted Blue (8)",
        description: "Predicted Outcome Eight",
        click_action: null,
        click_url: null,
      },
      {
        id: "blue-9",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/fc74bd90-2b74-4f56-8e42-04d405e10fae/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/fc74bd90-2b74-4f56-8e42-04d405e10fae/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/fc74bd90-2b74-4f56-8e42-04d405e10fae/3",
        title: "Predicted Blue (9)",
        description: "Predicted Outcome Nine",
        click_action: null,
        click_url: null,
      },
      {
        id: "gray-1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/144f77a2-e324-4a6b-9c17-9304fa193a27/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/144f77a2-e324-4a6b-9c17-9304fa193a27/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/144f77a2-e324-4a6b-9c17-9304fa193a27/3",
        title: "Predicted Gray (1)",
        description: "Predicted Gray (1)",
        click_action: null,
        click_url: null,
      },
      {
        id: "gray-2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/097a4b14-b458-47eb-91b6-fe74d3dbb3f5/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/097a4b14-b458-47eb-91b6-fe74d3dbb3f5/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/097a4b14-b458-47eb-91b6-fe74d3dbb3f5/3",
        title: "Predicted Gray (2)",
        description: "Predicted Gray (2)",
        click_action: null,
        click_url: null,
      },
      {
        id: "pink-1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/75e27613-caf7-4585-98f1-cb7363a69a4a/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/75e27613-caf7-4585-98f1-cb7363a69a4a/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/75e27613-caf7-4585-98f1-cb7363a69a4a/3",
        title: "Predicted Pink (1)",
        description: "Predicted Outcome One",
        click_action: null,
        click_url: null,
      },
      {
        id: "pink-2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/4b76d5f2-91cc-4400-adf2-908a1e6cfd1e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/4b76d5f2-91cc-4400-adf2-908a1e6cfd1e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/4b76d5f2-91cc-4400-adf2-908a1e6cfd1e/3",
        title: "Predicted Pink (2)",
        description: "Predicted Outcome Two",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "premium",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/bbbe0db0-a598-423e-86d0-f9fb98ca1933/3",
        title: "Prime Gaming",
        description: "Prime Gaming",
        click_action: "visit_url",
        click_url: "https://gaming.amazon.com",
      },
    ],
  },
  {
    set_id: "psychonauts_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/a9811799-dce3-475f-8feb-3745ad12b7ea/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/a9811799-dce3-475f-8feb-3745ad12b7ea/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/a9811799-dce3-475f-8feb-3745ad12b7ea/3",
        title: "Psychonauts",
        description: "Psychonauts",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Psychonauts/details",
      },
    ],
  },
  {
    set_id: "raiden-v-directors-cut_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/441b50ae-a2e3-11e7-8a3e-6bff0c840878/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/441b50ae-a2e3-11e7-8a3e-6bff0c840878/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/441b50ae-a2e3-11e7-8a3e-6bff0c840878/3",
        title: "Raiden V",
        description: "Raiden V",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Raiden%20V/details",
      },
    ],
  },
  {
    set_id: "rift_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/f939686b-2892-46a4-9f0d-5f582578173e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/f939686b-2892-46a4-9f0d-5f582578173e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/f939686b-2892-46a4-9f0d-5f582578173e/3",
        title: "RIFT",
        description: "RIFT",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Rift/details",
      },
    ],
  },
  {
    set_id: "rplace-2023",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/e33e0c67-c380-4241-828a-099c46e51c66/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/e33e0c67-c380-4241-828a-099c46e51c66/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/e33e0c67-c380-4241-828a-099c46e51c66/3",
        title: "r/place 2023 Cake",
        description:
          "A very delicious badge earned by watching Reddit's r/place 2023 event on Twitch Rivals or other participating channels.",
        click_action: "visit_url",
        click_url: "https://www.reddit.com/r/place/",
      },
    ],
  },
  {
    set_id: "samusoffer_beta",
    versions: [
      {
        id: "0",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/aa960159-a7b8-417e-83c1-035e4bc2deb5/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/aa960159-a7b8-417e-83c1-035e4bc2deb5/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/aa960159-a7b8-417e-83c1-035e4bc2deb5/3",
        title: "beta_title1",
        description: "beta_title1",
        click_action: "visit_url",
        click_url: "https://twitch.amazon.com/prime",
      },
    ],
  },
  {
    set_id: "staff",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/d97c37bd-a6f5-4c38-8f57-4e4bef88af34/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/d97c37bd-a6f5-4c38-8f57-4e4bef88af34/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/d97c37bd-a6f5-4c38-8f57-4e4bef88af34/3",
        title: "Staff",
        description: "Twitch Staff",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/jobs?ref=chat_badge",
      },
    ],
  },
  {
    set_id: "starbound_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/e838e742-0025-4646-9772-18a87ba99358/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/e838e742-0025-4646-9772-18a87ba99358/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/e838e742-0025-4646-9772-18a87ba99358/3",
        title: "Starbound",
        description: "Starbound",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Starbound/details",
      },
    ],
  },
  {
    set_id: "strafe_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/0051508d-2d42-4e4b-a328-c86b04510ca4/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/0051508d-2d42-4e4b-a328-c86b04510ca4/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/0051508d-2d42-4e4b-a328-c86b04510ca4/3",
        title: "Strafe",
        description: "Strafe",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/strafe/details",
      },
    ],
  },
  {
    set_id: "streamer-awards-2024",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/efc07d3d-46e4-4738-827b-a5bf3508983a/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/efc07d3d-46e4-4738-827b-a5bf3508983a/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/efc07d3d-46e4-4738-827b-a5bf3508983a/3",
        title: "Streamer Awards 2024",
        description:
          "You've completed all categories of the 2024 Twitch Predicts: The Streamer Awards extension!",
        click_action: "visit_url",
        click_url: "https://thestreamerawards.com/home",
      },
    ],
  },
  {
    set_id: "sub-gift-leader",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/21656088-7da2-4467-acd2-55220e1f45ad/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/21656088-7da2-4467-acd2-55220e1f45ad/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/21656088-7da2-4467-acd2-55220e1f45ad/3",
        title: "Gifter Leader 1",
        description: "Ranked as a top subscription gifter in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/0d9fe96b-97b7-4215-b5f3-5328ebad271c/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/0d9fe96b-97b7-4215-b5f3-5328ebad271c/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/0d9fe96b-97b7-4215-b5f3-5328ebad271c/3",
        title: "Gifter Leader 2",
        description: "Ranked as a top subscription gifter in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "3",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/4c6e4497-eed9-4dd3-ac64-e0599d0a63e5/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/4c6e4497-eed9-4dd3-ac64-e0599d0a63e5/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/4c6e4497-eed9-4dd3-ac64-e0599d0a63e5/3",
        title: "Gifter Leader 3",
        description: "Ranked as a top subscription gifter in this community",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "sub-gifter",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/a5ef6c17-2e5b-4d8f-9b80-2779fd722414/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/a5ef6c17-2e5b-4d8f-9b80-2779fd722414/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/a5ef6c17-2e5b-4d8f-9b80-2779fd722414/3",
        title: "Sub Gifter",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "10",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/d333288c-65d7-4c7b-b691-cdd7b3484bf8/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/d333288c-65d7-4c7b-b691-cdd7b3484bf8/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/d333288c-65d7-4c7b-b691-cdd7b3484bf8/3",
        title: "10 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "100",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/8343ada7-3451-434e-91c4-e82bdcf54460/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/8343ada7-3451-434e-91c4-e82bdcf54460/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/8343ada7-3451-434e-91c4-e82bdcf54460/3",
        title: "100 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "1000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/bfb7399a-c632-42f7-8d5f-154610dede81/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/bfb7399a-c632-42f7-8d5f-154610dede81/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/bfb7399a-c632-42f7-8d5f-154610dede81/3",
        title: "1000 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "150",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/514845ba-0fc3-4771-bce1-14d57e91e621/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/514845ba-0fc3-4771-bce1-14d57e91e621/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/514845ba-0fc3-4771-bce1-14d57e91e621/3",
        title: "150 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "200",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c6b1893e-8059-4024-b93c-39c84b601732/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c6b1893e-8059-4024-b93c-39c84b601732/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c6b1893e-8059-4024-b93c-39c84b601732/3",
        title: "200 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "2000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/4e8b3a32-1513-44ad-8a12-6c90232c77f9/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/4e8b3a32-1513-44ad-8a12-6c90232c77f9/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/4e8b3a32-1513-44ad-8a12-6c90232c77f9/3",
        title: "2000 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "25",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/052a5d41-f1cc-455c-bc7b-fe841ffaf17f/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/052a5d41-f1cc-455c-bc7b-fe841ffaf17f/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/052a5d41-f1cc-455c-bc7b-fe841ffaf17f/3",
        title: "25 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "250",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/cd479dc0-4a15-407d-891f-9fd2740bddda/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/cd479dc0-4a15-407d-891f-9fd2740bddda/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/cd479dc0-4a15-407d-891f-9fd2740bddda/3",
        title: "250 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "300",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/9e1bb24f-d238-4078-871a-ac401b76ecf2/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/9e1bb24f-d238-4078-871a-ac401b76ecf2/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/9e1bb24f-d238-4078-871a-ac401b76ecf2/3",
        title: "300 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "3000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/b18852ba-65d2-4b84-97d2-aeb6c44a0956/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/b18852ba-65d2-4b84-97d2-aeb6c44a0956/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/b18852ba-65d2-4b84-97d2-aeb6c44a0956/3",
        title: "3000 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "350",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/6c4783cd-0aba-4e75-a7a4-f48a70b665b0/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/6c4783cd-0aba-4e75-a7a4-f48a70b665b0/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/6c4783cd-0aba-4e75-a7a4-f48a70b665b0/3",
        title: "350 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "400",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/6f4cab6b-def9-4d99-ad06-90b0013b28c8/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/6f4cab6b-def9-4d99-ad06-90b0013b28c8/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/6f4cab6b-def9-4d99-ad06-90b0013b28c8/3",
        title: "400 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "4000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/efbf3c93-ecfa-4b67-8d0a-1f732fb07397/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/efbf3c93-ecfa-4b67-8d0a-1f732fb07397/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/efbf3c93-ecfa-4b67-8d0a-1f732fb07397/3",
        title: "4000 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "450",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/b593d68a-f8fb-4516-a09a-18cce955402c/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/b593d68a-f8fb-4516-a09a-18cce955402c/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/b593d68a-f8fb-4516-a09a-18cce955402c/3",
        title: "450 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "5",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ee113e59-c839-4472-969a-1e16d20f3962/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ee113e59-c839-4472-969a-1e16d20f3962/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ee113e59-c839-4472-969a-1e16d20f3962/3",
        title: "5 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "50",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c4a29737-e8a5-4420-917a-314a447f083e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c4a29737-e8a5-4420-917a-314a447f083e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c4a29737-e8a5-4420-917a-314a447f083e/3",
        title: "50 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "500",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/60e9504c-8c3d-489f-8a74-314fb195ad8d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/60e9504c-8c3d-489f-8a74-314fb195ad8d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/60e9504c-8c3d-489f-8a74-314fb195ad8d/3",
        title: "500 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "5000",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/d775275d-fd19-4914-b63a-7928a22135c3/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/d775275d-fd19-4914-b63a-7928a22135c3/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/d775275d-fd19-4914-b63a-7928a22135c3/3",
        title: "5000 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "550",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/024d2563-1794-43ed-b8dc-33df3efae900/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/024d2563-1794-43ed-b8dc-33df3efae900/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/024d2563-1794-43ed-b8dc-33df3efae900/3",
        title: "550 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "600",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/3ecc3aab-09bf-4823-905e-3a4647171fc1/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/3ecc3aab-09bf-4823-905e-3a4647171fc1/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/3ecc3aab-09bf-4823-905e-3a4647171fc1/3",
        title: "600 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "650",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/eeabf43c-8e4c-448d-9790-4c2172c57944/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/eeabf43c-8e4c-448d-9790-4c2172c57944/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/eeabf43c-8e4c-448d-9790-4c2172c57944/3",
        title: "650 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "700",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/4a9acdc7-30be-4dd1-9898-fc9e42b3d304/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/4a9acdc7-30be-4dd1-9898-fc9e42b3d304/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/4a9acdc7-30be-4dd1-9898-fc9e42b3d304/3",
        title: "700 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "750",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ca17277c-53e5-422b-8bb4-7c5dcdb0ac67/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ca17277c-53e5-422b-8bb4-7c5dcdb0ac67/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ca17277c-53e5-422b-8bb4-7c5dcdb0ac67/3",
        title: "750 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "800",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/9c1fb96d-0579-43d7-ba94-94672eaef63a/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/9c1fb96d-0579-43d7-ba94-94672eaef63a/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/9c1fb96d-0579-43d7-ba94-94672eaef63a/3",
        title: "800 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "850",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/cc924aaf-dfd4-4f3f-822a-f5a87eb24069/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/cc924aaf-dfd4-4f3f-822a-f5a87eb24069/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/cc924aaf-dfd4-4f3f-822a-f5a87eb24069/3",
        title: "850 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "900",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/193d86f6-83e1-428c-9638-d6ca9e408166/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/193d86f6-83e1-428c-9638-d6ca9e408166/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/193d86f6-83e1-428c-9638-d6ca9e408166/3",
        title: "900 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
      {
        id: "950",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/7ce130bd-6f55-40cc-9231-e2a4cb712962/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/7ce130bd-6f55-40cc-9231-e2a4cb712962/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/7ce130bd-6f55-40cc-9231-e2a4cb712962/3",
        title: "950 Gift Subs",
        description:
          "Has gifted a subscription to another viewer in this community",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "subscriber",
    versions: [
      {
        id: "0",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/3",
        title: "Subscriber",
        description: "Subscriber",
        click_action: "subscribe_to_channel",
        click_url: null,
      },
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/5d9f2208-5dd8-11e7-8513-2ff4adfae661/3",
        title: "Subscriber",
        description: "Subscriber",
        click_action: "subscribe_to_channel",
        click_url: null,
      },
      {
        id: "2",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/25a03e36-2bb2-4625-bd37-d6d9d406238d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/25a03e36-2bb2-4625-bd37-d6d9d406238d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/25a03e36-2bb2-4625-bd37-d6d9d406238d/3",
        title: "2-Month Subscriber",
        description: "2-Month Subscriber",
        click_action: "subscribe_to_channel",
        click_url: null,
      },
      {
        id: "3",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/e8984705-d091-4e54-8241-e53b30a84b0e/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/e8984705-d091-4e54-8241-e53b30a84b0e/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/e8984705-d091-4e54-8241-e53b30a84b0e/3",
        title: "3-Month Subscriber",
        description: "3-Month Subscriber",
        click_action: "subscribe_to_channel",
        click_url: null,
      },
      {
        id: "4",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/2d2485f6-d19b-4daa-8393-9493b019156b/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/2d2485f6-d19b-4daa-8393-9493b019156b/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/2d2485f6-d19b-4daa-8393-9493b019156b/3",
        title: "6-Month Subscriber",
        description: "6-Month Subscriber",
        click_action: "subscribe_to_channel",
        click_url: null,
      },
      {
        id: "5",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/b4e6b13a-a76f-4c56-87e1-9375a7aaa610/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/b4e6b13a-a76f-4c56-87e1-9375a7aaa610/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/b4e6b13a-a76f-4c56-87e1-9375a7aaa610/3",
        title: "9-Month Subscriber",
        description: "9-Month Subscriber",
        click_action: "subscribe_to_channel",
        click_url: null,
      },
      {
        id: "6",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ed51a614-2c44-4a60-80b6-62908436b43a/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ed51a614-2c44-4a60-80b6-62908436b43a/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ed51a614-2c44-4a60-80b6-62908436b43a/3",
        title: "6-Month Subscriber",
        description: "1-Year Subscriber",
        click_action: "subscribe_to_channel",
        click_url: null,
      },
    ],
  },
  {
    set_id: "superhot_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c5a06922-83b5-40cb-885f-bcffd3cd6c68/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c5a06922-83b5-40cb-885f-bcffd3cd6c68/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c5a06922-83b5-40cb-885f-bcffd3cd6c68/3",
        title: "Superhot",
        description: "Superhot",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/superhot/details",
      },
    ],
  },
  {
    set_id: "superultracombo-2023",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/5864739a-5e58-4623-9450-a2c0555ef90b/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/5864739a-5e58-4623-9450-a2c0555ef90b/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/5864739a-5e58-4623-9450-a2c0555ef90b/3",
        title: "SuperUltraCombo 2023",
        description: "This user joined Twitch's SuperUltraCombo 2023",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "the-game-awards-2023",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/10cf46de-61e7-4a42-807a-7898408ce352/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/10cf46de-61e7-4a42-807a-7898408ce352/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/10cf46de-61e7-4a42-807a-7898408ce352/3",
        title: "The Game Awards 2023",
        description:
          "You’ve completed all categories of the 2023 Twitch Predicts: The Game Awards extension!",
        click_action: "visit_url",
        click_url:
          "https://blog.twitch.tv/2023/11/30/the-2023-game-awards-is-live-on-twitch-december-7th/",
      },
    ],
  },
  {
    set_id: "the-golden-predictor-of-the-game-awards-2023",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c84c4dd7-9318-4e8b-9f01-1612d3f83dae/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c84c4dd7-9318-4e8b-9f01-1612d3f83dae/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c84c4dd7-9318-4e8b-9f01-1612d3f83dae/3",
        title: "The Golden Predictor of the Game Awards 2023",
        description:
          "You've predicted the entire 2023 Game Awards perfectly, here is a special gift for your work. Go ahead, show it off!",
        click_action: "visit_url",
        click_url:
          "https://blog.twitch.tv/2023/11/30/the-2023-game-awards-is-live-on-twitch-december-7th/",
      },
    ],
  },
  {
    set_id: "the-surge_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c9f69d89-31c8-41aa-843b-fee956dfbe23/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c9f69d89-31c8-41aa-843b-fee956dfbe23/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c9f69d89-31c8-41aa-843b-fee956dfbe23/3",
        title: "The Surge",
        description: "The Surge",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/The%20Surge/details",
      },
    ],
  },
  {
    set_id: "the-surge_2",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/2c4d7e95-e138-4dde-a783-7956a8ecc408/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/2c4d7e95-e138-4dde-a783-7956a8ecc408/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/2c4d7e95-e138-4dde-a783-7956a8ecc408/3",
        title: "The Surge",
        description: "The Surge",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/The%20Surge/details",
      },
    ],
  },
  {
    set_id: "the-surge_3",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/0a8fc2d4-3125-4ccb-88db-e970dfbee189/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/0a8fc2d4-3125-4ccb-88db-e970dfbee189/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/0a8fc2d4-3125-4ccb-88db-e970dfbee189/3",
        title: "The Surge",
        description: "The Surge",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/The%20Surge/details",
      },
    ],
  },
  {
    set_id: "this-war-of-mine_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/6a20f814-cb2c-414e-89cc-f8dd483e1785/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/6a20f814-cb2c-414e-89cc-f8dd483e1785/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/6a20f814-cb2c-414e-89cc-f8dd483e1785/3",
        title: "This War of Mine",
        description: "This War of Mine",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/This%20War%20of%20Mine/details",
      },
    ],
  },
  {
    set_id: "titan-souls_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/092a7ce2-709c-434f-8df4-a6b075ef867d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/092a7ce2-709c-434f-8df4-a6b075ef867d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/092a7ce2-709c-434f-8df4-a6b075ef867d/3",
        title: "Titan Souls",
        description: "Titan Souls",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Titan%20Souls/details",
      },
    ],
  },
  {
    set_id: "treasure-adventure-world_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/59810027-2988-4b0d-b88d-fc414c751305/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/59810027-2988-4b0d-b88d-fc414c751305/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/59810027-2988-4b0d-b88d-fc414c751305/3",
        title: "Treasure Adventure World",
        description: "Treasure Adventure World",
        click_action: "visit_url",
        click_url:
          "https://www.twitch.tv/directory/game/Treasure%20Adventure%20World/details",
      },
    ],
  },
  {
    set_id: "turbo",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/bd444ec6-8f34-4bf9-91f4-af1e3428d80f/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/bd444ec6-8f34-4bf9-91f4-af1e3428d80f/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/bd444ec6-8f34-4bf9-91f4-af1e3428d80f/3",
        title: "Turbo",
        description: "A subscriber of Twitch's monthly premium user service",
        click_action: "turbo",
        click_url: null,
      },
    ],
  },
  {
    set_id: "twitch-intern-2023",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/e239e7e0-e373-4fdf-b95e-3469aec28485/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/e239e7e0-e373-4fdf-b95e-3469aec28485/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/e239e7e0-e373-4fdf-b95e-3469aec28485/3",
        title: "Twitch Intern 2023",
        description: "This user was an intern at Twitch for the summer of 2023",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/jobs/early-career/",
      },
    ],
  },
  {
    set_id: "twitch-recap-2023",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/4d9e9812-ba9b-48a6-8690-13f3f338ee65/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/4d9e9812-ba9b-48a6-8690-13f3f338ee65/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/4d9e9812-ba9b-48a6-8690-13f3f338ee65/3",
        title: "Twitch Recap 2023",
        description:
          "This user bled purple like it was their job, and was one of the most engaged members of Twitch in 2023!",
        click_action: "visit_url",
        click_url: "https://twitch-web.app.link/e/twitch-recap",
      },
    ],
  },
  {
    set_id: "twitchbot",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/df9095f6-a8a0-4cc2-bb33-d908c0adffb8/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/df9095f6-a8a0-4cc2-bb33-d908c0adffb8/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/df9095f6-a8a0-4cc2-bb33-d908c0adffb8/3",
        title: "AutoMod",
        description: "AutoMod",
        click_action: "visit_url",
        click_url: "http://link.twitch.tv/automod_blog",
      },
    ],
  },
  {
    set_id: "twitchcon-2024---rotterdam",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/95b10c66-775c-4652-9b86-10bd3a709422/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/95b10c66-775c-4652-9b86-10bd3a709422/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/95b10c66-775c-4652-9b86-10bd3a709422/3",
        title: "TwitchCon 2024 - Rotterdam",
        description: "Attended TwitchCon Rotterdam 2024",
        click_action: "visit_url",
        click_url:
          "https://twitchcon.com/rotterdam-2024/?utm_source=twitch&utm_medium=chat-badge&utm_campaign=tceu24-chat-badge",
      },
    ],
  },
  {
    set_id: "twitchcon2017",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/0964bed0-5c31-11e7-a90b-0739918f1d9b/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/0964bed0-5c31-11e7-a90b-0739918f1d9b/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/0964bed0-5c31-11e7-a90b-0739918f1d9b/3",
        title: "TwitchCon 2017 - Long Beach",
        description: "Attended TwitchCon Long Beach 2017",
        click_action: "visit_url",
        click_url: "https://www.twitchcon.com/",
      },
    ],
  },
  {
    set_id: "twitchcon2018",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/e68164e4-087d-4f62-81da-d3557efae3cb/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/e68164e4-087d-4f62-81da-d3557efae3cb/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/e68164e4-087d-4f62-81da-d3557efae3cb/3",
        title: "TwitchCon 2018 - San Jose",
        description: "Attended TwitchCon San Jose 2018",
        click_action: "visit_url",
        click_url:
          "https://www.twitchcon.com/?utm_source=twitch-chat&utm_medium=badge&utm_campaign=tc18",
      },
    ],
  },
  {
    set_id: "twitchconAmsterdam2020",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ed917c9a-1a45-4340-9c64-ca8be4348c51/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ed917c9a-1a45-4340-9c64-ca8be4348c51/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ed917c9a-1a45-4340-9c64-ca8be4348c51/3",
        title: "TwitchCon 2020 - Amsterdam",
        description: "Registered for TwitchCon Amsterdam 2020",
        click_action: "visit_url",
        click_url:
          "https://www.twitchcon.com/amsterdam/?utm_source=twitch-chat&utm_medium=badge&utm_campaign=tcamsterdam20",
      },
    ],
  },
  {
    set_id: "twitchconEU2019",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/590eee9e-f04d-474c-90e7-b304d9e74b32/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/590eee9e-f04d-474c-90e7-b304d9e74b32/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/590eee9e-f04d-474c-90e7-b304d9e74b32/3",
        title: "TwitchCon 2019 - Berlin",
        description: "Attended TwitchCon Berlin 2019",
        click_action: "visit_url",
        click_url:
          "https://europe.twitchcon.com/?utm_source=twitch-chat&utm_medium=badge&utm_campaign=tceu19",
      },
    ],
  },
  {
    set_id: "twitchconEU2022",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/e4744003-50b7-4eb8-9b47-a7b1616a30c6/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/e4744003-50b7-4eb8-9b47-a7b1616a30c6/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/e4744003-50b7-4eb8-9b47-a7b1616a30c6/3",
        title: "TwitchCon 2022 - Amsterdam",
        description: "Attended TwitchCon Amsterdam 2022",
        click_action: "visit_url",
        click_url:
          "https://www.twitchcon.com/amsterdam-2022/?utm_source=twitch-chat&utm_medium=badge&utm_campaign=tceu22",
      },
    ],
  },
  {
    set_id: "twitchconEU2023",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/a8f2084e-46b9-4bb9-ae5e-00d594aafc64/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/a8f2084e-46b9-4bb9-ae5e-00d594aafc64/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/a8f2084e-46b9-4bb9-ae5e-00d594aafc64/3",
        title: "TwitchCon 2023 - Paris",
        description: "TwitchCon 2023 - Paris",
        click_action: "visit_url",
        click_url:
          "https://www.twitchcon.com/paris-2023/?utm_source=chat_badge",
      },
    ],
  },
  {
    set_id: "twitchconNA2019",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/569c829d-c216-4f56-a191-3db257ed657c/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/569c829d-c216-4f56-a191-3db257ed657c/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/569c829d-c216-4f56-a191-3db257ed657c/3",
        title: "TwitchCon 2019 - San Diego",
        description: "Attended TwitchCon San Diego 2019",
        click_action: "visit_url",
        click_url:
          "https://www.twitchcon.com/?utm_source=twitch-chat&utm_medium=badge&utm_campaign=tcna19",
      },
    ],
  },
  {
    set_id: "twitchconNA2020",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ed917c9a-1a45-4340-9c64-ca8be4348c51/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ed917c9a-1a45-4340-9c64-ca8be4348c51/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ed917c9a-1a45-4340-9c64-ca8be4348c51/3",
        title: "TwitchCon 2020 - North America",
        description: "Registered for TwitchCon North America 2020",
        click_action: "visit_url",
        click_url:
          "https://www.twitchcon.com/?utm_source=twitch-chat&utm_medium=badge&utm_campaign=tcna20",
      },
    ],
  },
  {
    set_id: "twitchconNA2022",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/344d429a-0b34-48e5-a84c-14a1b5772a3a/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/344d429a-0b34-48e5-a84c-14a1b5772a3a/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/344d429a-0b34-48e5-a84c-14a1b5772a3a/3",
        title: "TwitchCon 2022 - San Diego",
        description: "Attended TwitchCon San Diego 2022",
        click_action: "visit_url",
        click_url:
          "https://www.twitchcon.com/san-diego-2022/?utm_source=twitch-chat&utm_medium=badge&utm_campaign=tcna22",
      },
    ],
  },
  {
    set_id: "twitchconNA2023",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c90a753f-ab20-41bc-9c42-ede062485d2c/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c90a753f-ab20-41bc-9c42-ede062485d2c/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c90a753f-ab20-41bc-9c42-ede062485d2c/3",
        title: "TwitchCon 2023 - Las Vegas",
        description: "Attended TwitchCon Las Vegas 2023",
        click_action: "visit_url",
        click_url: "https://www.twitchcon.com/en/las-vegas-2023/",
      },
    ],
  },
  {
    set_id: "tyranny_1",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/0c79afdf-28ce-4b0b-9e25-4f221c30bfde/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/0c79afdf-28ce-4b0b-9e25-4f221c30bfde/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/0c79afdf-28ce-4b0b-9e25-4f221c30bfde/3",
        title: "Tyranny",
        description: "Tyranny",
        click_action: "visit_url",
        click_url: "https://www.twitch.tv/directory/game/Tyranny/details",
      },
    ],
  },
  {
    set_id: "user-anniversary",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/ccbbedaa-f4db-4d0b-9c2a-375de7ad947c/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/ccbbedaa-f4db-4d0b-9c2a-375de7ad947c/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/ccbbedaa-f4db-4d0b-9c2a-375de7ad947c/3",
        title: "Twitchiversary Badge",
        description: "Staff badge celebrating Twitch tenure",
        click_action: null,
        click_url: null,
      },
    ],
  },
  {
    set_id: "vga-champ-2017",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/03dca92e-dc69-11e7-ac5b-9f942d292dc7/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/03dca92e-dc69-11e7-ac5b-9f942d292dc7/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/03dca92e-dc69-11e7-ac5b-9f942d292dc7/3",
        title: "2017 VGA Champ",
        description: "2017 VGA Champ",
        click_action: "visit_url",
        click_url:
          "https://blog.twitch.tv/watch-and-co-stream-the-game-awards-this-thursday-on-twitch-3d8e34d2345d",
      },
    ],
  },
  {
    set_id: "vip",
    versions: [
      {
        id: "1",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/3",
        title: "VIP",
        description: "VIP",
        click_action: "visit_url",
        click_url:
          "https://help.twitch.tv/customer/en/portal/articles/659115-twitch-chat-badges-guide",
      },
    ],
  },
  {
    set_id: "warcraft",
    versions: [
      {
        id: "alliance",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/c4816339-bad4-4645-ae69-d1ab2076a6b0/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/c4816339-bad4-4645-ae69-d1ab2076a6b0/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/c4816339-bad4-4645-ae69-d1ab2076a6b0/3",
        title: "Alliance",
        description: "For Lordaeron!",
        click_action: "visit_url",
        click_url: "http://warcraftontwitch.tv/",
      },
      {
        id: "horde",
        image_url_1x:
          "https://static-cdn.jtvnw.net/badges/v1/de8b26b6-fd28-4e6c-bc89-3d597343800d/1",
        image_url_2x:
          "https://static-cdn.jtvnw.net/badges/v1/de8b26b6-fd28-4e6c-bc89-3d597343800d/2",
        image_url_4x:
          "https://static-cdn.jtvnw.net/badges/v1/de8b26b6-fd28-4e6c-bc89-3d597343800d/3",
        title: "Horde",
        description: "For the Horde!",
        click_action: "visit_url",
        click_url: "http://warcraftontwitch.tv/",
      },
    ],
  },
];

export function getBadgeFromSetId(setId, version, size = 2) {
  const set = GLOBAL_BADGES.find((set) => set.set_id === setId);
  if (!set) {
    return null;
  }
  const _version = set.versions.find((v) => version === v.id);

  if (!_version) {
    return null;
  }
  return _version[`image_url_${size}x`];
}
